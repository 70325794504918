.authentication-wrapper {
    z-index: 2;
    float: none;
    max-width: unset;

    @include media("<=phone") {
        margin-top: -40px;
    }

    @include media(">phone") {
        width: 100%;
    }

    @include media(">large") {
        width: calc(100% - 450px);
    }

    @include media(">desktop") {
        width: calc(100% - 580px);
    }

    @media only screen and (min-width: 1400px) {
        width: calc(100% - 560px);
    }

    @media only screen and (min-width: 1600px) {
        width: calc(100% - 440px);
    }

    .action {
        color: $black;
        font-weight: 600;
        text-decoration: underline;
        font-size: 1.4rem;
    }

    .authentication-dropdown {
        .modal-header {
            padding: 0;

            .action-close {
                margin: 1.5rem;
            }
        }

        .modal-inner-wrap {
            @include media("<=phone") {
                padding: 15px;
            }
        }

        .block-authentication {
            .block-title {
                margin-bottom: 1.5rem;

                strong {
                    font-weight: 700;
                    font-size: 2rem;
                    text-transform: uppercase;
                }
            }

            label {
                font-size: 1.3rem;
                color: #555;
                font-weight: 500;
            }
        }

        .block-content {
            .actions-toolbar {
                display: flex;
                align-items: center;
                margin: 0;

                &:before {
                    display: none;
                }

                .primary {
                    width: auto !important;

                    .action {
                        text-decoration: none;
                        background-color: $primary--light;
                        border-color: $primary--light;
                        border-radius: 0;
                        font-weight: 700;
                        font-size: 1.5rem;
                    }

                    ~ .secondary {
                        margin-left: 2rem;
                        padding: 0;

                        .action {
                            font-size: 1.3rem;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}

body {
    &._has-auth-shown {
        .modal-custom-overlay {
            background: rgba($black,0.25);
        }
    }
}
