._show {
  .modal-inner-wrap {
    .modal-header,
    .modal-footer {
      height: inherit;
      padding: 20px 48px;
    }

    .modal-content {
      margin: 20px 0;
    }
  }

  .modal-popup .modal-title {
    border: none;
    color: $white;
    line-height: inherit;
    margin: 0;
    min-height: inherit;
    padding: 0;
  }

  .modal-custom .action-close:hover:before,
  .modal-popup .action-close:hover:before,
  .modal-slide .action-close:hover:before {
    color: $white;
  }
}
