div.mage-error, .field-error, .message-error {
    border: none !important;
    background-color: transparent !important;
    color: #E1004C !important;
    font-size: 13px !important;
    line-height: 19px !important;
    font-family: $font-family__sans-serif !important;
    padding: 0 0 0 30px !important;
    position: relative;
    margin-top: 12px !important;

    &::before {
        content: "" !important;

        position: absolute;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
        height: 20px;
        width: 20px;
        background-color: #E1004C;
        display: block;
        border: none;
        mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-x' viewBox='0 0 16 16'%3E%3Cpath d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
        mask-size: contain;
        mask-size: 20px;
    }

    > *::before {
        content: "" !important;
    }
}
