@mixin font($font-family:$font-family__sans-serif, $font-size: null, $color:inherit, $font-weight:inherit, $line-height:null, $font-style:null, $text-decoration:null, $text-transform:null, $letter-spacing:null, $text-align:null, $font-variant: null, $speak:null) {
  font-family: $font-family;
  font-weight: $font-weight;
  //@include font-size($font-size-data: $font-size);
  color: $color;
  line-height: $line-height;
  font-style: $font-style;
  text-decoration: $text-decoration;
  text-transform: $text-transform;
  letter-spacing: $letter-spacing;
  text-align: $text-align;
  @include font-smoothing(antialiased);
}