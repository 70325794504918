body.checkout-index-index {
    .opc-progress-bar {
        display: none;
    }

    header {
        background-color: transparent;
        .header {
            background-color: transparent;

            &:before,
            &:after {
                display: none;
            }
        }
    }

    #checkout, .page-title-wrapper {
        @include media("<=tablet") {
            padding: 0 15px 15px;
        }
    }

    .checkout-block {
        margin: 0 0 15px;
    }

    .minicart-items-wrapper {
        overflow-x: visible;
    }

    .opc-wrapper {
        > .checkout-column.opc {
            width: 100%;
            padding: 0;

            &:last-of-type {
                @include media("<desktop") {
                    flex-basis: 100% !important;
                    width: 100% !important;
                    max-width: 100% !important;
                }
            }
        }

        #co-shipping-form {
            margin-top: 0;
        }

        select {
            height: 50px;
        }

        &.am-opc-wrapper .payment-option-content {
            padding: 0 0 20px;
        }

        .button-continue {
            background: #f2f2f2;
            border: 1px solid #cdcdcd;
            color: #333;
            margin-top: 1rem;
            display: inline-flex;
            align-items: center;
            padding: 7px 15px 7px 0;
            font-size: 1.4rem;
            line-height: 1.6rem;

            &:before {
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                font-size: 32px;
                line-height: 16px;
                color: inherit;
                content: "";
                font-family: "icons-blank-theme";
                margin: 0;
                vertical-align: top;
                display: inline-block;
                font-weight: normal;
                overflow: hidden;
                speak: none;
                text-align: center;
            }

            &:hover {
                background: #e2e2e2;
                border: 1px solid #cdcdcd;
                color: #555;
            }
        }
    }

    .table-checkout-shipping-method {
        input {
            opacity: 1;
        }
    }

    .form-shipping-address {
        label, .control {
            width: 100% !important;
        }

        label {
            text-align: left !important;
        }

        .fieldset > .field.required {
            margin-bottom: 0;
        }
    }

    .opc-block-summary {
        .product-item-name {
            font-family: $font-family__sans-serif--bold;
            font-size: 16px;
            font-weight: 600;

            &::before {
                display: none;
            }
        }

        .items-in-cart > .title::after {
            margin: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        .minicart-items {
            .product-image-container {
                margin-left: 0;
            }
        }
    }

    .checkout-header {
        display: none;
    }

    .page-main > .page-title-wrapper .page-title {
        z-index: 1;

        @include media(">=phone") {
            margin-top: 20px;
        }
    }

    .form-login {
        .note {
            &::before {
                margin: 0;
            }
        }
    }

    #s_method_flatrate_flatrate {
        margin-top: 6px;
    }
}

.checkout-cart-index {
    .page.messages {
        .message-success {
            display: none;
        }
    }

    #maincontent {
        padding: 5rem 0;

        @include media("<=phone") {
            padding: 2.5rem 15px;
        }
    }

    .page-title-wrapper {
        margin-bottom: 2.5rem;
    }

    .cart-container {
        form {
            @include media("<tablet") {
                margin-bottom: 30px;
            }
        }
    }

    table > tbody > tr > td {
        vertical-align: middle;
    }

    input[type=number] {
        width: 74px !important;
        height: 45px;
        text-align: left !important;
        padding-left: 16px;

        &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
            opacity: 1 !important;
        }
    }

    .page-title {
        font-size: 40px;
        margin: 0;
        font-weight: 700;
        text-transform: uppercase;

        @include media("<=phone") {
            font-size: 2.5rem;
        }
    }

    .form-cart {
        thead {
            display: none;
        }

        .col.price {
            display: none;
        }

        .col.subtotal {
            .price {
                font-family: $font-family__sans-serif--medium;
                font-weight: 500;
            }
        }

        .product-item-details {
            vertical-align: middle;
        }

        .action.update {
            padding-top: 0;
            padding-bottom: 0;

            &::before {
                vertical-align: middle;
                font-size: 40px;
            }
        }

        .item-actions {
            .action-edit {
                display: none;
            }

            td {
                padding-right: 0;
                text-align: right;
                padding-top: 0;
            }

            .actions-toolbar {
                display: flex;
                justify-content: flex-end;
                padding: 0;

                .action-delete {
                    appearance: none;
                    color: rgba(#1A2021, 0.4);
                    font-family: $font-family__sans-serif;
                    font-size: 14px;
                    text-decoration: underline;
                    line-height: 28px;
                    margin: 0;
                    border: none;
                    border-radius: 0;
                    padding: 0;
                    background-color: #fff;
                }
            }
        }
    }

    .cart-summary {
        padding: 1.5rem;
        background-color: $secondary--lighter;
        position: relative !important;

        > .title {
            margin-top: 0;
            font-weight: 500;
            font-size: 1.5rem;
        }

        #block-shipping {
            display: none;
        }

        .cart-totals {
            border: none;
            padding: 0;

            .amount {
                text-align: left;
            }
        }

        .block > .title {
            border: none;
        }

        table.totals {
            .totals-tax {
                border-bottom: 2px solid #E8E8E8;

                > td {
                    position: relative;

                    &::after {
                        content: "";
                        width: 12px;
                        height: 12px;
                        position: absolute;
                        background-image: url("../svg/filter-plus.svg");
                        background-repeat: no-repeat;
                        background-size: 12px;
                        background-position: center;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%);
                    }
                }
            }

            .grand.totals {
                .mark {
                    vertical-align: bottom;
                }

                .amount {
                    strong {
                        font-weight: normal;

                        .price {
                            font-family: $font-family__sans-serif--medium;
                            font-size: 2.5rem;
                            line-height: 30px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }

        .checkout-methods-items {
            margin-top: 0;

            .checkout.tocart {
                margin-bottom: 0;
            }
        }
    }

    #block-discount {
        .title {
            margin-top: 10px;
            text-decoration: underline;
            display: inline-block;

            &:after {
                display: none;
            }

            #block-discount-heading {
                font-size: 1.4rem;
            }
        }

        &.active {
            .apply.primary {
                @extend %medium-dark-button;

                > span {
                    top: 0;
                }
            }
        }
    }

    .product-item-details {
        > strong {
            &::before {
                display: none;
            }
        }
    }
}

.checkout-payment-method {
    .payment-option-title {
        padding: 0 0 20px 0;
    }

    .payment-method-billing-address {
        margin: 0;
    }
}

.opc-wrapper .fieldset {
    > .legend + br {
        display: none;
    }

    > .field {
        margin: 0 0 10px;

        .additional {
            display: none;
        }

        &:not(.choice) {
            > .control {
                width: 100%;
            }
        }

        > .label {
            width: 100%;
            text-align: left;
        }

        &.street {
            margin-bottom: 10px !important;
        }
    }
}

.opc-wrapper .field .control {
    label {
        display: flex;
        align-items: center;

        img {
            flex-shrink: 0;
        }

        input {
            &:not([type="radio"]) {
                opacity: 1;
                position: relative;
                margin-left: 5px;
                display: inline-block;
                width: 18px;
                height: 18px;
                cursor: pointer;
            }
        }
    }
}

.checkout-container {
    .authentication-wrapper {
        @include media("<=phone") {
            float: right;
        }
    }
}

body.checkout-onepage-success {
    @include media("<tablet") {
        padding: 0 15px;
    }

    .action.primary {
        background-color: #f2df00;
        border: 2px solid #f2df00;
        height: 50px;
        font-weight: 700;
        color: #000;
        font-family: "IntroBlackCaps";
        line-height: 16px;
        display: flex;
        align-items: center;

        > span {
            position: relative;
            top: 2px;
        }

        &:hover {
            background-color: #000;
            color: #f2df00;
            border: 2px solid #000;
        }
    }

    #registration {
        border: none;
        padding: 0;
        margin: 15px 0 25px;

        form {
            position: relative;
            top: auto;
            transform: none;
            right: auto;
        }

        div:last-of-type {
            display: flex;
            flex-direction: column;

            form {
                order: -1;
            }

            p {
                border-left: 3px solid #f2df00;
                padding-left: 10px;
                margin-top: 15px;
            }

            p {
                &:last-of-type {
                    display: none;
                }
            }
        }
    }

    .block-content {
        display: flex;
        flex-wrap: wrap;

        > .box {
            .box-title {
                font-family: $font-family__title;
            }
        }
    }

    .success-messages {
        margin-bottom: 10px;
    }

    address {
        line-height: 28px;
    }

    .table-order-items {
        .price-including-tax {
            .price {
                font-family: $font-family__sans-serif__bold;
                font-weight: normal;
                position: relative;
                top: 6px;
                font-size: 16px;
            }
        }

        .grand_total {
            .price {
                font-family: $font-family__sans-serif__bold;
                font-weight: normal;
                position: relative;
                top: 1px;
            }
        }
    }
}

body.customer-account-create {
    .form-create-account {
        fieldset.create {
            margin-bottom: 20px;
        }

        .customer-name-prefix {
            margin-bottom: 5px;

            .label {
                padding-top: 0;
            }

            .fields {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;

                .field-name-prefix {
                    flex-basis: 100%;

                    .control {
                        width: 100px;
                    }
                }

                .field-name-lastname {
                    margin-right: 0;
                }

                .field {
                    &:not(.field-name-prefix) {
                        flex-basis: 49%;
                    }
                }
            }
        }
    }
}

.minicart-wrapper .action.showcart {
    margin-top: 25px;

    .counter.qty {
        width: 18px;
        height: 18px;
        background-color: #F2DF00;
        border-radius: 50%;
        font-size: 12px;
        color: #1A2021;
        margin: 0;
        padding: 0;
        line-height: 18px;
        position: absolute;
        right: -4px;
    }
}

.minicart-wrapper {
    color: #1A2021;
    font-family: $font-family__sans-serif--light;
    order: 2;

    .product-item {
        padding: 8.5px 0;
        border-bottom: 1px solid #E8E8E8;
    }

    .product {
        display: flex;
        align-items: flex-start;
        width: 100%;
    }

    .block-minicart {
        padding: 35px 20px 20px;

        .block-title {
            display: block;
            font-size: 1.6rem;
            line-height: 1.4;
            margin-bottom: 1rem;

            strong {
                font-weight: 600;
            }
        }

        .subtitle.empty {
            font-weight: 400;
        }

        .product-image-container {
            max-width: 58px;
            max-height: 47px;

            .product-image-photo {
                margin: 0;
                max-width: 58px;
                max-height: 47px;
            }
        }

        .product-item-details {
            padding: 0 0 0 12px;
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;
            flex-direction: row;

            .price {
                font-weight: 500 !important;
            }

            .options {
                flex-direction: column;
            }

            .details-qty {
                label {
                    width: auto;
                    font-size: 1.3rem;
                }

                .item-qty {
                    border: 1px solid #d8d8d8;
                    border-radius: .2rem;
                }
            }
        }

        .price-container {
            font-family: $font-family__sans-serif__bold;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;

            .price-including-tax {
                margin: 0;
            }
        }

        .subtotal, .freeshipping {
            margin: 12px 0 0;
            display: flex;
            justify-content: flex-end;
            font-family: $font-family__sans-serif;
            font-size: 14px;
            align-items: center;

            span[class*=price-wrapper] {
                min-width: 61px;
                text-align: right;
                margin-left: 30px;
            }

            .label {
                &::after {
                    display: none;
                }
            }
        }

        .freeshipping {
            span.price {
                color: $succes-content;
                text-transform: capitalize;
            }
        }

        .subtotal {
            margin-top: 0;
        }
    }

    .actions {
        display: flex;
        justify-content: center;

        .primary {
            margin: 0 !important;

            button {
                border-radius: 0;
                font-weight: 700;
            }
        }

        .secondary {
            a {
                color: $secondary--dark;
                font-size: 1.3rem;
            }
        }

        button {
            padding: 0.75rem 2rem !important;
            background-color: $primary--light;
            border: 2px solid $primary--light;
            color: $black;
            font-size: 14px !important;
            width: auto !important;
            margin: 20px 0 0 auto !important;
            font-weight: 600;

            &:hover {
                background-color: #F2DF00;
                border: 2px solid #F2DF00;
                color: #1A2021;
            }
        }
    }

    .minicart-items-wrapper {
        height: auto !important;
        border: none;
        margin: 1.5rem 0 0 0;
        padding: 0;
    }

    .product-item-name {
        margin: 0;
        flex: 100%;
        font-weight: 600;
        line-height: 1.3;

        &::before {
            display: none;
        }

        a {
            text-transform: unset;
            font-weight: 600;
            font-size: 1.5rem;
            color: $black;
        }
    }

    .product-item-details {
        padding-right: 0;

        .options {
            .toggle {
                font-size: 1.3rem;
            }
        }

        .content {
            .options {
                display: flex;
                font-size: 1.3rem;
                margin-bottom: 1rem;
                flex-direction: row;

                .label {
                    margin-right: 5px;
                    margin-bottom: 0;
                }

                .values {
                    margin: 0;
                }
            }
        }
    }

    .minicart-widgets {
        margin-top: 0;
    }
}

.minicart-items .product-item {
    &:not(:first-child) {
        border-bottom: 1px solid #E8E8E8;
        border-top: none;
    }

    &:first-child {
        border-bottom: 1px solid #E8E8E8;
    }
}

button.action-apply {
    background-color: transparent;
    border: 2px solid $primary--dark;
    height: 50px;
    font-weight: 700;
    color: $primary--dark;
    font-family: "IntroBlackCaps";

    &:hover {
        color: #f2df00;
        background-color: $primary--dark;
    }
}

#mypa-slider {
    #mypa-tabs-container {
        height: 66px;

        .mypa-tab, .mypa-date:checked + label {
            background-color: #f2df00;
        }
    }

    .mypa-delivery-header {
        background-color: #f2df00;
    }
}

// Safari fix

div[class*=container], div[class*=column] {
    &::before, &::after {
        width: 0;
    }
}

.cart-empty {
    a {
        font-weight: bold;
        color: $black;

        &:hover {
            text-decoration: underline;
        }
    }
}


