$white: rgb(255, 255, 255);
$black: #0F1112;

$primary--dark: #1A2021;
$primary--light: #F7D000;
$primary--light_dark: #D2C100;
$primary--lighter: #FAF8DB;

$primary--light__hover: #F2DF00;

$secondary--dark: #747474;
$secondary--light: #e3e3dd;
$secondary--lighter: #f7f7f7;

$secondary--green: #138E53;
$secondary--orange: #FA8200;

$gallery-nav--light: #D8D8D8;
$gallery-nav--dark: #828282;

/* Messages */
$error-bg: #FFF1F0;
$error-content: #E1004C;
$infobox-bg: #EBF7FD;
$infobox-content: #0483B3;
$succes-bg: #F2FAE2;
$succes-content: #66AD21;
$warning-bg: #FFFBE0;
$warning-content: #FD9B00;

/* Defaults */
$default-font-color: $primary--dark;
$default-border-color: $secondary--light;
$default-form-element-color: $primary--dark;

