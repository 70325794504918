.bluefoot-entity.category_banner {
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.category_banner-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: $primary--dark;
    top: 0;
    left: 0;
    opacity: 0.5;
    z-index: 0;

}

.bluefoot-entity.category_banner,
.category_banner--wrapper {
    min-height: 240px;
    height: 100px; // IE fix
    @include media("<=phone") {
        min-height: 180px;
    }
}

.category_banner--wrapper {
    @include container-size();
    display: flex;
    flex-direction: column;
    justify-content: center;


    h2 {
        color: $white;
        position: relative;
        padding-left: 50px;
        width: 34%;
        @include media("<=phone") {
            left: 15px;
            padding-right: 15px;
            width: calc(100% - 15px);
        }

        &:before {
            background-color: $white;
        }
    }

    a {
        color: $primary--light;
        font-family: $font-family__title;
        margin: 20px 28px 0 0;
        @include media("<=phone") {
            margin: 10px 28px 0 0;
            &:not(.button) {
                display: block;
            }
        }
    }

    a.button {
        background: $primary--light;
        border: none;
        color: $black;
        display: inline-block;
        height: 48px;
        padding-left: 18px;

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            overflow: hidden;
        }

        @supports (-ms-ime-align: auto) {
            overflow: hidden;
        }

        > span:before {
            background: $primary--light_dark;
            height: 48px;
            right: -80px;
        }


        > span:after {
            border-color: transparent transparent $primary--light_dark transparent;
            right: -40px;
        }

        > span:before,
        > span:after {
            top: -14px;
            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                top: -13px;
            }
            @supports (-webkit-overflow-scrolling: touch) {
                top: -13px;
            }
            @-moz-document url-prefix() {
                top: -13px;
            }
            @supports (-ms-ime-align: auto) {
                top: -13px;
            }
        }

        svg {
            height: 22px;
            position: absolute;
            right: -70px;
            top: -2px;
            width: 22px;
            z-index: 5;
            fill: $black;

            &.icon--active {
                display: none;
            }
        }
    }
}

.category_banner--locationbox {
    padding-left: 50px;
    z-index: 10;
    @include media('<=phone') {
        padding-left: 15px;
    }
}

.bluefoot-wrapper {
    @include media("<=phone") {
        margin-top: 0;
    }
}

/* for every class starting with bluefoot-column- set width to 100% */
.columns-2 div[class*='bluefoot-column-'] {
    @include media("<=phone") {
        width: 100%;
    }
}
