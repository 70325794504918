.cat-img-wrapper {
    background-color: #000;
}

.category-image--wrapper {
    @include big-container-size();
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 300px;
    overflow: hidden;
    position: relative;
    z-index: 3;

    @include media("<=tablet") {
        &,
        .category-image {
            height: 280px;
        }
    }
    @include media("<=phone") {
        &,
        .category-image {
            height: 185px;
        }
    }

    .category-image {
        @include container-size();
        display: flex;
        flex-direction: column;
        height: inherit;
        justify-content: center;
        margin-bottom: 0;
        position: relative;
        align-items: flex-start;

        @include media("<=tablet", ">phone") {
            height: 280px;
        }

        @include media("<=phone") {
            height: 185px;
        }
    }
}

.pagebuilder-poster-overlay {
    justify-content: flex-start !important;
}

.pagebuilder-collage-content,
.pagebuilder-poster-content,
.category-image__inner {
    height: 100%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    max-width: unset !important;
    width: auto !important;
    margin: 0 !important;

    h1,
    p {
        color: $white;
        font-weight: 300;
        margin: 0;
        position: relative;
        z-index: 2;
    }

    h1 {
        font-size: 40px;
        font-weight: 700;
        text-transform: uppercase;

        @include media("<=phone") {
            font-size: 20px;
        }
    }

    p {
        font-size: 20px;

        @include media("<=phone") {
            font-size: 14px;
        }
    }

    &:before,
    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        top: 0;
        transform: skewX(-20deg);
    }

    &:before {
        background: $primary--light;
        background: linear-gradient(0deg, rgba($primary--light, 0.9) 0%, rgba($primary--light, 0.15) 100%);
        width: 60px;
        right: calc(-20% + -60px);

        @include media("<=phone") {
            width: 30px;
            right: calc(-20% + -30px);
        }
    }

    &:after {
        background-color: rgba(15, 17, 18, 0.8);
        width: 220%;
        left: -100%;
    }
}
