footer.page-footer {
    overflow: hidden;

    .footer.content {
        border-top: none;
        margin-top: 0;
        padding: 0;
        background-color: #000206;

        .footer-columns--wrapper {
            padding: 8rem 0;

            @include media('<=tablet', '>phone') {
                padding: 5rem 0;
            }

            &:before {
                display: none;
            }

            @include media('<=phone') {
                padding-top: 0;
                padding-bottom: 10px;
            }

            .column-container {
                @include media(">phone") {
                    display: flex;
                    justify-content: space-between;
                    margin: 0 -15px;
                }

                @include media('<=tablet', '>phone') {
                    flex-wrap: wrap;
                }
            }
        }

        .footer-columns {
            background: $black;
            padding: 0 15px;
            position: relative;
            z-index: 10;

            @include media("<=tablet") {
                overflow: hidden;
            }

            @include media('<=phone') {
                padding: 40px 15px 5px 15px;
                width: 100%;
            }

            &:before {
                @include media('>=tablet') {
                    content: "";
                    background-image: url("../svg/beeldmerk-footer.svg");
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: left top;
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    width: 38%;
                    opacity: 0.6;
                }
            }
        }

        .build-select:nth-child(1) {
            @include media('>=tablet') {
                margin-top: 2.5rem;
            }

            h5 {
                color: $white;
                font-family: $font-family__title;
                font-size: 28px;
                font-weight: 400;
                line-height: 34px;
                margin: -17px 0 20px 0;

                @include media("<=desktop", ">tablet") {
                    margin-top: -5px;
                }
                @include media("<=phone") {
                    margin-top: 0;
                }
            }

            p, p a {
                color: $primary--light;
                font-size: 24px;
                font-weight: 600;
                line-height: 40px;
            }

            br {
                display: none;
            }

            p {
                display: block;
                margin: 0;

                &:first-of-type {
                    &:before {
                        content: "";
                        background: linear-gradient(80deg, rgba($primary--light, 0.9) 0%, rgba($primary--light, 0) 100%);
                        width: 50%;
                        height: 1px;
                        display: block;
                    }

                    @include media("<=tablet") {
                        display: block;
                    }
                }

                span {
                    color: #E3E3E2;
                    display: block;
                    font-size: 14px;
                    line-height: 14px;
                    margin-top: 1rem;
                    font-weight: 300;
                }
            }
        }

        .build-select:nth-child(1) {
            @include media("<=phone") {
                padding: 0 0 40px 0;
            }
        }

        .footer-column {
            padding: 0;
            position: relative;
            padding: 0 15px;

            @include media(">phone") {
                width: 33.333%;
            }

            @include media('<=tablet', '>phone') {
                width: 50%;

                &:last-of-type {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;

                    [data-content-type='html'] {
                        order: 2;
                        padding: 0 15px
                    }

                    [data-content-type='html'],
                    .social {
                        width: 50%;
                    }

                    .social {
                        order: 1;

                        h4 {
                            padding-top: 0;
                        }
                    }

                    .newsletter-wrapper {
                        width: 100%;
                        order: 3;
                    }
                }
            }

            &:nth-child(4) {
                padding: 0;
            }

            h4 {
                font-weight: 800;
                text-transform: uppercase;
            }

            @include media('<=phone') {
                border-top: 1px solid $secondary--dark;
                padding: 0;
                &:nth-child(1) {
                    border-top: none;
                }
                &:nth-child(4) {
                    padding: 0;
                }
            }

            ul {
                @include media('<=phone') {
                    margin: 0 0 5px 0;
                    padding: 0 0 15px 0;
                }

                li {
                    &:nth-child(1) {
                        padding-top: 10px;

                        @include media('<=phone') {
                            padding-top: 0;
                        }
                    }

                    .block {
                        float: none;
                        margin: 0;
                    }

                    a {
                        color: $white;
                        font-size: 16px;
                        font-family: $font-family__serif;
                        line-height: 35px;

                        @include media('<=phone') {
                            color: $white;
                            font-size: 14px;
                            line-height: 32px;
                        }
                    }
                }
            }

            .newsletter-wrapper {
                margin-top: 1.5rem;

                h4 {
                    margin-bottom: 10px;

                    svg {
                        display: none;
                    }
                }

                .block.newsletter {
                    .field {
                        margin-right: 40px;
                        position: relative;

                        #newsletter {
                            background-color: #8b8b8c !important;
                            border: 0 !important;

                            &::placeholder {
                               color: $black;
                            }
                        }

                        &:after {
                            content: "";
                            background-color: #8b8b8c;
                            position: absolute;
                            right: -10px;
                            top: 0;
                            bottom: 0;
                            z-index: -1;
                            transform: skewX($skew-angle);
                            width: 30px;
                        }
                    }

                    #newsletter-error {
                        position: absolute;
                        padding: 0 !important;
                        color: red !important;
                    }

                    .action.subscribe {
                        background-color: $primary--light;
                        color: $black;
                        border: 0;
                        position: relative;
                        padding-left: 0;

                        &:before {
                            content: "";
                            background-color: $primary--light;
                            position: absolute;
                            left: -20px;
                            top: 0;
                            bottom: 0;
                            z-index: -1;
                            transform: skewX($skew-angle);
                            width: 30px;
                        }
                    }
                }
            }
        }
    }
}

.social {
    margin-top: 30px;

    h4 {
        svg {
            display: none;
        }
    }

    ul {
        color: $white;
        font-family: $font-family__title;
        line-height: 26px;
        margin: 0 -12px !important;
        display: block !important;

        a {
            margin-left: 12px;

            &:hover {
                text-decoration: none;

                .icon {
                    fill: $secondary--dark;
                }
            }
        }
    }
}

.footer-columns h4 {
    color: $white;
    margin: 0;
    font-size: 15px;
    font-family: $font-family__title;
    font-weight: 400;
    line-height: 18px;
    text-transform: capitalize;

    @include media('<=phone') {
        cursor: pointer;
        font-size: 16px;
        line-height: 49px;
        margin: 0;
        padding: 0;
        position: relative;

        &.service {
            border-top: 1px solid $secondary--dark;
        }

        svg {
            height: 15px;
            opacity: 1;
            position: absolute;
            right: 5px;
            top: 17px;
            transition: all .25s ease-out;
            width: 15px;
        }

        &.open {
            margin: 0;

            svg.plus {
                opacity: 0;
            }
        }
    }
}

.footer-column:nth-child(n+3) h4 {
    @include media("<=tablet", ">phone") {
        padding-top: 40px;
    }
}

.footer-bottombar {
    @include container-size();
    padding: 10px 0;

    @include media("<tablet") {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        flex-flow: row wrap;
        justify-content: center;
    }

    @media screen and (min-width: 992px) and (max-width: 1199px) {
        align-items: center;
    }
}

.footer-collaborations {
    img {
        max-width: 80px;
    }
}

.bottombar {
    &--store-switcher {
        display: flex;
        line-height: 40px;

        span.title {
            font-family: $font-family__title;
        }

        .switcher .options,
        .switcher .options ul.dropdown {
            border: 2px solid $secondary--light;
        }

        .switcher .options {
            height: 40px;
            margin-left: 20px;
            position: relative;
        }

        .switcher .options ul.dropdown {
            bottom: 36px;
            box-shadow: none;
            left: -2px;
            margin: -4px 0 0 0;
            padding: 7px 0;
            width: calc(100% + 4px);

            a {
                color: $secondary--dark;
                font-size: 14px;
                height: 30px;
                line-height: 32px;
                padding: 0 10px;
            }
        }

        .switcher .options .action.toggle.active:before,
        .switcher .options ul.dropdown:before,
        .switcher .options ul.dropdown:after {
            border: none;
            display: none;
        }

        .switcher .options .action.toggle {
            background: transparent;
            line-height: 36px;
            padding: 0 10px;
            position: relative;
            top: -2px;
            width: 160px;
            z-index: 1;

            &:before {
                content: '';
                display: none;
            }
        }
    }

    &--content {
        color: #DCDCDB;
        display: flex;

        @include media("<=desktop", ">tablet") {
            text-align: center;
            flex-wrap: wrap;
            justify-content: center;
        }

        @include media("<=phone") {
            display: inline-block;
            text-align: center;
        }

        @include media("<=large", ">desktop") {
            flex-wrap: wrap;
        }

        p {
            font-size: 14px;
            line-height: 26px;
            margin: 0;

            &.copy {
                margin-right: auto;
            }

            @include media("<=desktop", ">tablet") {
                width: 100%;
            }
        }

        [data-content-type="html"] {
            @include media(">=phone") {
                display: flex;
            }

            @include media('<=desktop', '>phone') {
                flex-wrap: wrap;
                justify-content: center;

                .copy {
                    width: 100%;
                    text-align: center;
                }
            }
        }

        .block.block-cms-link {
            line-height: 26px;
            margin: 0;
            padding: 0 5px;
            font-size: 14px;

            a {
                color: #DCDCDB;
            }

            @include media("<=phone") {
                display: inline;
            }
        }
    }

    &--paymenticons {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 40px;

        @include media(">=desktop") {
            margin-left: auto;
        }

        @include media("<phone") {
            margin: 14px 0 0 0;
        }

        ul {
            display: flex;

            li {
                &:not(:last-child) {
                    margin-right: 5px;
                }
            }

            img { // payment icon
                max-width: 36px;
                display: none;

                &[data-country*=nl] {
                    body[class*=favour-nl] & {
                        display: block;
                    }
                }

                &[data-country*=de] {
                    body[class*=favour-de] & {
                        display: block;
                    }
                }

                &[data-country*=be] {
                    body[class*=favour-be] & {
                        display: block;
                    }
                }
            }
        }
    }
}

.bottombar--content,
.bottombar--paymenticons,
.bottombar--store-switcher {
    @include media("<=phone") {
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}

.block.newsletter {
    width: 100%;

    .field .control:before {
        display: none;
    }

    #newsletter {
        background: $secondary--light;
    }

    .action.subscribe {
        height: 50px;
        border-radius: 0;
        border: 2px solid $primary--light_dark;
        background: transparent;
        color: $primary--light;
        font-family: $font-family__title;

        &:hover {
            color: black;
            background: $primary--light_dark;
        }

        @include media('<=phone') {
            margin-left: 10px;
        }
    }
}

@include media('<=tablet') {
    .bottombar--store-switcher {
        display: none;
    }
}

@include media('<=phone') {
    .hide-mobile {
        display: none;
    }
}

.bottombar--content {
    align-items: center;

    @include media('<=tablet') {
        flex-wrap: wrap;
    }
}

.g-recaptcha {
    display: none !important;
}

.copyright {
    display: none;
}
