[data-content-type='row'] {
    &[data-appearance='contained'] {
        @include container-size();

        [data-content-type='column-group'] {
            margin: 0 -15px;

            [data-content-type='column'] {
                padding: 0 15px;
                margin-bottom: 3rem;
            }
        }
    }

    &.row-usps {
        @include media("<=phone") {
            display: none !important;
        }

        [data-content-type="column-line"] {
            flex-wrap: nowrap;
        }

        [data-content-type='column'] {
            position: relative;
            flex-direction: row !important;

            @include media("<=tablet") {
                margin: 0 1rem;
                padding: 0 1rem;
            }

            @include media(">=tablet") {
                margin: 0 2.5rem;
                padding: 0 2.5rem;
            }

            @include media(">desktop") {
                margin: 0 3rem;
                padding: 0 3rem;
            }

            @include media(">large") {
                margin: 0 5rem;
                padding: 0 5rem;
            }

            &:not(:last-child) {
                &:after {
                    content: "";
                    background-color: $primary--light;
                    height: 100%;
                    width: 2px;
                    position: absolute;
                    right: 0;
                    top: 0;
                    transform: skewX($skew-angle);
                }
            }

            [data-content-type='image'] {
                display: flex;
                margin-right: 2.5rem;

                @include media("<=tablet") {
                    margin-right: 1rem;
                    width: 30px;
                }

                img {
                    width: 40px;
                }
            }

            [data-content-type='text'] {
                padding: 0.5rem 0;

                p {
                    margin: 0;
                    line-height: 1.3;

                    @include media("<=tablet") {
                        font-size: 1.3rem;
                    }

                    a {
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }

                strong {
                    font-size: 1.7rem;

                    @include media("<=phone") {
                        font-size: 1.5rem;
                    }
                }
            }
        }
    }

    &.row-products {
        &[data-appearance='full-width'] {
            > .row-full-width-inner {
                @include container-size();
                padding: 4.5rem 0;

                @include media("<=phone") {
                    padding: 3rem 0;
                }
            }
        }

        [data-content-type='column-group'] {
            [data-content-type='column'] {
                @include media("<=phone") {
                    flex-basis: 50%;
                }

                &:hover {
                    img {
                        @include media(">tablet") {
                            transform: translateY(-15px);
                        }
                    }
                }
            }

            [data-content-type='image'] {

                img {
                    height: 300px !important;
                    object-fit: contain;
                    transition: 0.3s ease;

                    @include media("<=phone") {
                        height: 150px !important;
                    }

                    @include media("<=tablet") {
                        height: 180px !important;
                    }
                }

                [data-element='link'] {
                    padding-bottom: 3.5rem;
                }
            }
        }
    }

    &.row-image-text,
    &.row-text-image {
        [data-content-type='image'] {
            @include media(">phone") {
                height: 100%;

                img {
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    &.row-image-text {
        [data-content-type='column'] {
            &:last-child {
                padding-top: 15px;
                padding-bottom: 15px;

                @each $name, $breakpoint in $breakpoints {
                    @include media(">=#{$name}") {
                        padding-right: calc(((100vw - #{map-get($breakpoints,  #{$name})} + 40px)) / 2);
                    }
                }

                @include media(">phone") {
                    padding-left: 3rem;
                }

                @include media(">large") {
                    padding-left: 10rem;
                }
            }
        }
    }

    &.row-text-image {
        [data-content-type='column'] {
            &:first-child {
                padding-top: 15px;
                padding-bottom: 15px;

                @each $name, $breakpoint in $breakpoints {
                    @include media(">=#{$name}") {
                        padding-left: calc(((100vw - #{map-get($breakpoints,  #{$name})} + 40px)) / 2);
                    }
                }

                @include media(">phone") {
                    padding-right: 3rem;
                }

                @include media(">large") {
                    padding-right: 10rem;
                }
            }
        }
    }

    &.row-divider-text {
        [data-content-type='text'] {
            @include media("<=phone") {
                padding: 0 15px;
            }

            p,
            span {
                @include media("<=phone") {
                    font-size: 1.8rem !important;
                }
            }
        }

        [data-content-type='buttons'] {
            margin: 2rem 0 0 0;

            [data-content-type='button-item'] {
                padding: 0;
            }
        }
    }

    &.row-navigation,
    .row-navigation {
        ol {
            counter-reset: step-counter;
            list-style-type: none;
            margin-left: 0;
            padding-bottom: 5rem !important;
            border-bottom: 1px solid #e3e3dd;

            li {
                margin-bottom: 0.5rem;
                margin-left: 0;
                font-weight: 600;

                &:before {
                    counter-increment: step-counter;
                    content: counters(step-counter, ".") ".";
                    color: #8a8a8a;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 27px;
                    letter-spacing: 0.18px;
                    margin-right: 15px;
                }

                a {
                    color: #8a8a8a;
                }
            }
        }
    }

    &.row-text,
    .row-text {
        > .row-full-width-inner {
            @include media(">phone") {
                padding: 7rem 0 !important;
            }

            @include media("<=phone") {
                padding: 0 15px !important;
            }

            > [data-content-type='heading'] {
                margin-top: 0;
            }
        }

        [data-content-type='heading'] {
            text-transform: uppercase;
            font-weight: 700;
        }

        [data-content-type='column-group'] {
            @include media(">phone") {
                margin: 0 -35px;
            }

            [data-content-type='column'] {
                @include media(">phone") {
                    padding: 0 35px;
                }
            }
        }

        [data-content-type='text'] {
            p {
                font-size: 1.4rem;
                line-height: 1.7;
            }
        }

        [data-content-type='divider'] {
            [data-element='line'] {
                display: none !important;
            }

            .read-more {
                font-weight: 600;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        [data-content-type='image'] {
            @include media("<=phone") {
                margin: 1.5rem -15px 0;
            }

            [data-element='mobile_image'] {
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &.row-images-usps {
        &[data-appearance='full-width'] {
            background-color: $secondary--lighter !important;

            > .row-full-width-inner {
                @include container-size();
                padding: 6rem 0;

                @include media("<=phone") {
                    padding: 2.5rem 0;
                }
            }
        }

        [data-content-type='column-group'] {
            @include media("<=phone") {
                justify-content: center;
            }

            [data-content-type='column'] {
                @include media("<=phone") {
                    flex-basis: unset;
                    width: 50% !important;
                    margin-bottom: 1.5rem;
                }
            }

            [data-content-type='image'] {
                img {
                    border-radius: 100%;
                    padding: 0 8rem;

                    @include media("<=tablet") {
                        padding: 0 3rem;
                    }
                }

                [data-element='caption'] {
                    margin-top: 2rem;
                }
            }
        }
    }

    &[data-appearance='full-width'] {
        > .row-full-width-inner {
            @include container-size();
            padding: 1.8rem 0;
        }
    }

    &[data-appearance='full-bleed'] {
        [data-content-type='column-group'] {
            [data-content-type='image'] {
                margin: 0;

                @include media("<=phone") {
                    margin: -15px;
                    max-height: 200px;
                }

                img {
                    width: 100%;
                    height: 100% !important;

                    @include media("<=phone") {
                        object-fit: cover;
                    }
                }
            }

            [data-content-type='column'] {
                @include media("<=phone") {
                    padding: 15px;
                }
            }

            [data-content-type='heading'] {
                font-weight: 800;

                @include media("<=phone") {
                    margin-top: 0;
                }
            }

            [data-content-type='buttons'] {
                .pagebuilder-button-link {
                    margin: 0;
                    padding: 0;
                    color: $black;
                    font-size: 1.6rem;
                }
            }
        }
    }

    [data-content-type='text'] {
        p {
            a {
                color: #000;
                font-weight: 500;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        ul {
            li {
                a {
                    color: #000;
                    font-weight: 500;
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}


[data-content-type='image'] {
    figcaption {
        top: -1rem;
        pointer-events: none;
        font-size: 2rem;
        font-weight: 700;
        text-transform: uppercase;

        @include media("<=tablet") {
            font-size: 1.7rem;
        }
    }
}

body {
    &.cms-page-view {
        [data-content-type='row'] {
            @include media("<=phone") {
                padding: 0 15px;
            }

            &.row-hero-image {
                padding: 0;

                [data-content-type='image'] {
                    display: flex;
                }

                .pagebuilder-mobile-hidden {
                    display: flex;
                }
            }
        }

        .columns .column.main {
            padding-bottom: 5rem;

            @include media("<=phone") {
                padding-bottom: 2.5rem;
            }
        }

        .row-text {
            max-width: 900px;
            margin: 0 auto;
            padding: 2.5rem 0 0 0;

            > h2 {
                &:first-of-type {
                    @include media("<=phone") {
                        margin-top: 0;
                    }
                }
            }
        }

        .row-divider-text {
            margin: 5rem 0;
        }
    }

    &.cms-noroute-index {
        background-color: #050600;

        [data-content-type='row'] {
            .wrapper404 {
                padding: 10rem 0;

                @include media("<=phone") {
                    padding: 2.5rem 20px;
                    background-position: relative;
                    background-position: right -85px center !important;
                }

                [data-content-type='text'] {
                    h1 {
                        span {
                            &:first-of-type {
                                font-size: 10rem;

                                @include media("<=phone") {
                                    font-size: 5rem;
                                }
                            }

                            &:last-of-type {
                                font-size: 4rem !important;
                                text-transform: none;
                                font-weight: 400;

                                @include media("<=phone") {
                                    font-size: 2rem !important;
                                }
                            }
                        }
                    }

                    p {
                        font-size: 2rem;
                        line-height: 1.7;

                        @include media("<=phone") {
                            font-size: 1.5rem;
                            padding-right: 5rem;

                            &:empty {
                                display: none;
                            }
                        }
                    }
                }

                [data-content-type="buttons"] {
                    [data-content-type="button-item"] {
                        [data-element="link_text"] {
                            color: #fff;
                        }
                    }

                    .pagebuilder-button-primary {
                        &:after {
                            background-color: #fff;
                        }
                    }
                }
            }
        }
    }

    &.cms-nieuwsbrief {
        .content {
            padding-bottom: 5rem;
            max-width: 900px;
            margin: 0 auto;

            .actions {
                .primary.subscribe {
                    background-color: $primary--light !important;
                    color: $black !important;
                    border-color: $primary--light !important;
                }
            }
        }
    }
}

[data-content-type='column-line'] {
    flex-wrap: wrap;
}
