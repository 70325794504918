#confirmBox {
    .added-item {
        font-family: $font-family__sans-serif--bold;

        + p {
            color: #138E53;
            font-family: $font-family__sans-serif--medium;
            font-size: 17px;
            line-height: 28px;
            position: relative;
            display: inline-block;

            &::before {
                content: "";
                width: 16px;
                height: 16px;
                position: absolute;
                background-image: url("../svg/check.svg");
                background-repeat: no-repeat;
                background-size: 16px;
                background-position: center;
                top: 50%;
                left: -2rem;
                transform: translateY(-50%);
            }
        }
    }

    .am-photo-container, #amcart-count, .amcart-subtotal {
        display: none;
    }

    .am-btn-left {
        height: 32px;
        color: $primary--dark;
        font-family: $font-family__sans-serif--bold;
        font-size: 17px;
        line-height: 32px;
        border-radius: 0;
        width: auto;
        box-shadow: none;
        padding: 0;
        position: relative;
        top: -2px;
        margin-right: 100px;

        &::before {
            content: "";
            width: 12px;
            height: 12px;
            position: absolute;
            background-image: url("../svg/chevron.svg");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            top: 54%;
            right: -2rem;
            transform: translateY(-50%);
            transition: all .1s ease-in;
        }

        &:hover {
            &::before {
                right: -2.5rem;
            }
        }
    }

    .am-btn-right {
        border: 2px solid $primary--dark;
        background-color: white;
        color: #1A2021;
        border-radius: 0;
        font-size: 14px;
        font-family: $font-family__title;
        text-transform: uppercase;
        box-shadow: none;
        height: auto;
        width: auto;
        line-height: 30px;
        height: 32px;
        padding: 0 15px;

        &:hover {
            background-color: $primary--dark;
            color: $primary--light;
            opacity: 1;
        }
    }

}
