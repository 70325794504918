.faq-category-view {
    .columns {
        @include container-size();
        overflow: hidden;

        @include media("<phone") {
            padding: 2.5rem 15px;
        }

        @include media(">=phone") {
            padding: 5rem 0;
        }

        .column.main {
            @include media(">phone") {
                width: 70%;
            }

            @include media(">tablet") {
                width: 80%;
            }

            .am-back-button {
                display: none;
            }

            .amfaq_questions {
                .am-item {
                    border-bottom: 2px solid $secondary--light;
                    font-weight: 500;

                    .am-title {
                        padding: 0.5rem 0;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        cursor: pointer;
                        font-size: 1.6rem;

                        .am-minus,
                        .am-plus {
                            display: none;
                        }

                        &:after {
                            content: "";
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-plus' viewBox='0 0 16 16'%3E%3Cpath d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3E%3C/svg%3E");
                            background-size: cover;
                            width: 20px;
                            height: 20px;
                        }

                        &[aria-expanded="true"] {
                            &:after {
                                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-dash' viewBox='0 0 16 16'%3E%3Cpath d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/%3E%3C/svg%3E");
                            }
                        }
                    }

                    .am-content {
                        p {
                            font-weight: 400;
                        }
                    }
                }
            }
        }

        .sidebar-additional {
            @include media("<phone") {
                margin-top: 2.5rem;
            }

            @include media(">phone") {
                width: 30%;
            }

            @include media(">tablet") {
                width: 20%;
            }

            .amfaq_categories {
                .item {
                    margin-bottom: 0.5rem;

                    &.title {
                        display: none;
                    }

                    &.current {
                        a {
                            font-weight: 600;
                            color: $black;
                        }
                    }

                    a {
                        color: $secondary--dark;
                    }
                }
            }
        }
    }
}
