html {
    scroll-behavior: smooth;
    scroll-padding-top: 200px;
    overflow: visible;
}

body {
    overflow-x: clip;

    &.open {
        width: 100%;
        position: unset;
        overflow: hidden;
    }
}

.page-header {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 100;
    background: $black;
    border-bottom: 0;

    @include media("<=phone") {
        margin-bottom: 0;
    }
}

.header.content {
    @include container-size();
    padding: 1.5rem 0;
    display: flex;
    align-items: center;
    width: 100%;
    background: $black;

    @include media("<=phone") {
        padding: 1rem 15px;
    }

    @include media("<=tablet") {
        display: flex;
        justify-content: center;
        width: 100%;
        position: relative;
    }

    &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        background: $black;
        height: 100%;

        @each $name, $breakpoint in $breakpoints {
            @include media(">=#{$name}") {
                width: calc(((100vw - #{map-get($breakpoints,  #{$name})} + 40px)) / 2);
                right: calc(((100vw - #{map-get($breakpoints,  #{$name})} + 40px)) / -2);
            }
        }
    }

    .language-drop {
        order: 4;
        display: flex;
        position: relative;
        align-items: center;
        cursor: pointer;

        @include media("<=phone") {
            margin-left: 1rem;
        }

        @include media(">tablet") {
            margin-left: 2rem;
            padding-left: 2rem;
            border-left: 1px solid #575757;
        }

        picture {
            display: flex;
        }

        img {
            width: 25px;
            height: 25px;
            border-radius: 100%;
        }

        .title {
            color: #DCDCDB;
            font-size: 18px;
            font-weight: 700;
            margin-right: 15px;
            text-transform: uppercase;
        }

        .icon.arrow {
            width: 15px;
            height: 15px;
            position: absolute;
            right: 10px;
            top: 17px;
            transform: rotate(90deg);
            transition: transform .15s ease-in;
        }

        > div:hover .icon.arrow {
            transform: rotate(270deg);

        }

        > div {
            &[data-content-type="html"] {
                position: relative;
                background-color: $black;
            }
        }

        > div {
            &[data-content-type="html"] {
                > div {
                    display: flex;
                    align-items: center;
                }

                span {
                    display: flex;
                    align-items: center;
                    padding: 10px;
                    font-weight: 700;

                    img {
                        margin-right: 5px;
                    }
                }
            }
        }

        ul {
            position: absolute;
            top: 100%;
            right: 0;
            z-index: 50;
            background: white;
            width: 180px;
            display: none;
            list-style: none;
            border-radius: 5px;
            overflow: hidden;
        }

        > div:hover ul {
            display: block;
        }

        ul li {
            padding: 10px;
            border: 1px solid $default-border-color;
            border-bottom: none;
            margin: 0;
            display: flex;
            align-items: center;

            a {
                color: #000;
                font-size: 13px;
                width: 100%;
            }

            img {
                margin-right: 5px;
            }
        }
    }
}

.header.links {
    order: 2;
    margin-left: 1.5rem;
    list-style: none;

    @include media("<=tablet") {
        margin-left: 1rem;
    }

    li {
        margin-left: 0;
    }

    .my-account {
        mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-person' viewBox='0 0 16 16'%3E%3Cpath d='M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z'/%3E%3C/svg%3E");
        background-color: $white;
        mask-repeat: no-repeat;
        mask-size: contain;
        height: 32px;
        width: 32px;
    }
}

.logo {
    margin: 0;
    position: unset;

    &:before {
        content: "";
        background: #1B1D1D;
        top: 0;
        bottom: 0;
        left: -100%;
        position: absolute;
        height: 100%;
        z-index: -1;
        transform: skewX($skew-angle);
        pointer-events: none;

        @include media("<=phone") {
            width: calc(100% + 70px);
        }

        @include media(">phone") {
            width: calc(100% + 120px);
        }

        @include media(">=tablet") {
            width: calc(100% + 200px);
        }
    }

    @include media("<=phone") {
        margin-left: 55px;
    }

    img {
        @include media("<=tablet") {
            max-width: 90px;
        }

        @include media(">=tablet") {
            max-width: 170px;
        }
    }
}


.header__notification--wrapper {
    padding: 0.5rem 0;

    @include media("<=phone") {
        padding: 0.5rem 15px;
    }

    &.bg-secondary {
        background: $primary--light;
    }

    p,
    strong {
        margin: 0;
        font-weight: 600;
        line-height: 1.7;
        font-size: 1.4rem;

        @include media("<phone") {
            font-size: 1.2rem;
        }
    }
}

.block-search {
    order: 1;
    margin-left: auto;
    width: auto;
    padding-left: 0;
    position: unset;
    z-index: unset;
    margin-top: 0;

    @include media("<=tablet") {
        margin-left: 1rem;
    }

    @include media("<=phone") {
        margin-left: auto;
    }

    .minisearch {
        .control {
            #search {
                height: 60px;

                @include media("<phone") {
                    height: 45px;
                }
            }
        }

        &.active {
            .control {
                bottom: -60px;

                @include media("<phone") {
                    bottom: -45px;
                }
            }
        }
    }

    .control {
        padding: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: -1;
        transition: 0.3s ease;
        border: 0;
        margin: 0;

        @include media("<phone") {
            height: 45px;
        }

        &:before,
        &:after {
            content: "";
            position: absolute;
            background: #1B1D1D;
            top: 0;
            bottom: 0;

            @each $name, $breakpoint in $breakpoints {
                @include media(">=#{$name}") {
                    width: calc(((100vw - #{map-get($breakpoints,  #{$name})} + 40px)) / 2);
                }
            }
        }

        &:before {
            @each $name, $breakpoint in $breakpoints {
                @include media(">=#{$name}") {
                    left: calc(((100vw - #{map-get($breakpoints,  #{$name})} + 40px)) / -2);
                }
            }
        }

        &:after {
            @each $name, $breakpoint in $breakpoints {
                @include media(">=#{$name}") {
                    right: calc(((100vw - #{map-get($breakpoints,  #{$name})} + 40px)) / -2);
                }
            }
        }

        input {
            border: 0;
            height: 60px;
            line-height: 60px;
            background: #1B1D1D;
            padding: 0;
            color: #fff;
            left: 0;
            margin: 0;

            @include media("<=phone") {
                height: 45px;
                line-height: 45px;
                padding: 0 15px;
            }

            &:focus {
                border: 0;
                outline: 0;
            }
        }

        .actions {
            .search {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                padding: 0;

                &:before {
                    color: #8f8f8f;
                    font-size: 32px;
                }
            }
        }
    }

    label {
        cursor: pointer;

        .action.search {
            position: unset;
            opacity: 1 !important;
            padding: 0;
            display: flex;
            align-items: center;

            &:before {
                opacity: 1;
                color: $white;
                font-size: 32px;
                content: "";
                font-family: "icons-blank-theme";
            }

            &:hover {
                &:before {
                    color: #8b8b8c;
                }
            }
        }
    }
}
