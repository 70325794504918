.checkout-index-index {
    .geoip-popup {
        display: none;
    }
}

.geoip-popup {
    background-color: $primary--light;

    .container {
        @include container-size();
    }

    &__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0;

        @include media("<=phone") {
            display: block;
            padding: 1rem 15px;
        }

        p {
            margin-bottom: 0 !important;
            font-weight: 600;
            font-size: 1.4rem;
            margin-bottom: 1rem;
        }

        .btn {
            display: inline-block;
            border-radius: 20px;
            color: #fff;
            background: #000;
            padding: 0.5rem 2rem;
            font-weight: 600;
        }
    }
}
