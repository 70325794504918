.page-layout-2columns-left .column.main {
    width: 100%;
}

.column.main {
    .products.wrapper {
        margin-top: 0;

        .product-items {
            margin: 0;
            padding-top: 2.5rem;
        }

    }
}

ol.products.list {
    display: flex;
    flex-wrap: wrap;

    @include media("<=phone") {
        padding: 0 15px;
    }

    > li.product-item {
        width: calc(100% * 1/4 - 20px);
        margin-left: 0;
        margin-bottom: 0;
        padding-left: 0;
        position: relative;
        top: 0;
        transition: 0.25s cubic-bezier(0.25, 0.8, 0.25, 1);

        &:nth-child(n) {
            margin-right: 25px;
            clear: none;
        }

        &:nth-child(4n+1) {
            clear: both;
        }

        &:nth-child(4n) {
            margin-right: 0;
        }

        @include media("<=desktop") {
            width: calc(100% * 1/3 - 10px);

            &:nth-child(n) {
                margin-right: 15px;
                clear: none;
            }

            &:nth-child(3n) {
                margin-right: 0;
            }
        }

        @include media("<=tablet") {
            width: calc(100% * 1/2 - 10px);

            &:nth-child(n) {
                margin-right: 15px;
                clear: none;
            }

            &:nth-child(2n) {
                margin-right: 0;
            }
        }

        @include media("<=phone") {
            width: 100%;

            &:not(:first-child) {
                margin-top: 15px;
            }

            &:nth-child(n+1) {
                clear: both;
            }

            &:nth-child(n) {
                margin-right: 0;
                padding-left: 0;
            }
        }

        .product-item-info {
            width: 100%;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            height: 100%;
        }

        .product-item-inner {
            @include media(">=tablet") {
                opacity: 0;
                visibility: hidden;
            }
        }

        @include media(">=tablet") {
            &:hover {
                box-shadow: 0 7px 16px 0 rgba(0, 0, 0, 0.15);
                top: -10px;

                @include media("<=tablet") {
                    top: 0;
                }

                .product-item-info .image {
                    display: none;

                    &:nth-child(2) {
                        display: block;
                    }
                }

                .product-item-box {
                    display: block;
                }

                .product-item-inner {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}

.product-item-photo,
span.image {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100%;
}

.product-item-photo {
    position: relative;
    background-color: $secondary--lighter;

    svg {
        fill: $primary--light;
        width: 42px;
        height: 42px;
        position: absolute;
        z-index: 2;
        top: 30px;
        right: 43px;

        @include media("<=tablet") {
            right: 20px;
            top: 0;
        }
    }
}

.product-item-info {
    .image {
        display: block;

        &:nth-child(2) {
            display: none;
        }

        .product-image-wrapper {
            padding-bottom: 72.5% !important;
            background-color: $secondary--lighter;

            @include media("<=phone") {
                padding-bottom: 62.5% !important;
            }
        }

        .product-image-photo {
            object-fit: contain;
            mix-blend-mode: multiply;

            @include media("<=phone") {
                max-height: 220px;
            }

            @include media(">phone") {
                max-height: 280px;
            }

            @include media(">tablet") {
                max-height: 200px;
            }

            @include media(">=large") {
                max-width: 260px;
                max-height: 220px;
            }
        }
    }
}

.product-item-details {
    padding: 0 1.5rem;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    flex: 1;
    height: 100%;

    .product-name--wrapper {
        margin-bottom: 1rem;
    }

    .product-item-name {
        font-family: $font-family__title;
        font-size: 1.8rem;
        position: relative;

        a {
            text-transform: uppercase;
            font-weight: 700;
            font-size: 1.6rem;
        }

        > span {
            display: block;
            line-height: 14px;
            margin-bottom: 10px;
            text-transform: uppercase;
            color: #7d879c;
            font-size: 1.3rem;
            font-weight: 500;

            span:after {
                content: '/';
                font-family: $font-family__sans-serif;
                margin: 0 3px 0 5px;
            }

            span:last-of-type:after {
                content: '';
            }
        }
    }

    .product-reviews-summary {
        margin-top: 1rem;

        .rating-summary {
            left: 0;
        }

        .rating-count {
            color: #7d879c;
        }
    }

    .product_usps {
        margin: 0;
        padding-left: 15px;

        li {
            margin: 0;
            line-height: 1.7;
            font-size: 1.4rem;
            color: #373f50;
        }
    }

    .product-price--wrapper {
        margin: 1.5rem 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1;

        .price-box {
            display: flex;
            line-height: 1.2;
            margin: 0;

            .old-price {
                order: 1;

                .price-wrapper {
                    .price {
                        color: #7d879c;
                        font-size: 1.5rem;
                        font-weight: 500;
                        margin-left: 0;
                    }
                }
            }

            .special-price,
            .normal-price {
                order: 2;
            }

            span.price {
                font-size: 2rem;
                color: #343e40;
            }
        }

        .stock {
            display: flex;
            align-items: center;
            font-weight: 500;

            span {
                margin-left: 0.2rem;
            }

            &.available {
                color: $secondary--green;
            }

            &.unavailable {
                color: $error-content;
            }
        }
    }

    .product-item-inner {
        margin: 0 -15px;
        background-color: $black;

        .product-item-actions {
            margin: 0;
            display: flex;
            justify-content: space-between;

            .product-item-link {
                color: #cecece;
                font-weight: 500;
                font-size: 1.2rem;
                flex: 1;
                height: 50px;
                align-items: center;
                display: flex;
                justify-content: center;

                &:hover {
                    text-decoration: none;
                }
            }

            .tocart {
                width: 50px;
                margin: 0;
                border-radius: 0;
                padding-left: 10px;

                &:before {
                    content: "";
                    background-color: $primary--light;
                    width: 20px;
                    height: 100%;
                    transform: skewX($skew-angle);
                    position: absolute;
                    left: -10px;
                    top: 0;
                }

                &:hover {
                    background-color: #f2df00 !important;

                    &:before {
                        background-color: #f2df00;
                    }
                }

                svg {
                    width: 19px;
                    height: 19px;
                }
            }
        }
    }

    .swatch-attribute {
        margin-bottom: 1rem;

        &-options {
            display: flex;
            justify-content: flex-start;
            margin-top: 0;

            .swatch-option {
                width: 25px;
                height: 25px;
                min-width: 25px;
                border-radius: 100%;
                margin-right: 5px;
                pointer-events: none;
                margin-bottom: 0;

                &.selected,
                &:hover {
                    outline-color: $black;
                }
            }
        }
    }
}

.product-item-box {
    svg {
        bottom: -50px;
        right: 28px;
    }

    span {
        @include visible-for-screenreader;
    }
}
