.catalogsearch-result-index {
    .page-main {
        padding: 5rem 0;

        @include media("<phone") {
            padding: 2.5rem 15px;
        }

        .page-title-wrapper {
            .page-title {
                margin-bottom: 0;
            }
        }
    }
}
