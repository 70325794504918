.page-layout-checkout {
    background-color: $secondary--lighter;

    .page-main {
        max-width: 1280px;

        .logo {
            float: none;
            display: block;
            position: relative;
            padding: 20px 0;

            &:before {
                width: calc(100% + 170px);
            }

            @include media("<=tablet") {
                max-width: unset;
                background: #fff;
                padding: 10px 15px;
                width: calc(100% + 30px);
                margin-left: -15px !important;
                margin-top: 0;
                margin-bottom: 1.5rem;
                border-bottom: 1px solid #dee0e3;
            }

            img {
                max-width: 130px;

                @include media("<=tablet") {
                    margin-left: 15px;
                    max-width: 115px;
                }
            }
        }
    }

    .page-title-wrapper {
        position: relative;
        height: auto;
        width: 100%;
        margin-top: 1rem;

        @include media(">tablet") {
            margin-bottom: 1.5rem;
        }

        .page-title {
            @include media(">tablet") {
                margin-bottom: 1.5rem;
            }

            @include media("<=phone") {
                margin-bottom: 0;
            }
        }
    }

    .opc-progress-bar {
        padding: 0;

        @include media(">phone") {
            width: 100%;
            margin: 0 0 1rem 0;
        }

        @include media(">tablet") {
            margin: 0 0 2rem 0;
        }

        @include media(">large") {
            width: calc(100% - 450px);
        }

        @include media(">desktop") {
            width: calc(100% - 580px);
        }

        @media only screen and (min-width: 1400px) {
            width: calc(100% - 560px);
        }

        @media only screen and (min-width: 1600px) {
            width: calc(100% - 440px);
        }

        .opc-progress-bar-item {
            width: 33.333%;

            &:before {
                height: 3px;
            }

            span {
                font-weight: 600;
                white-space: nowrap;

                @include media(">phone") {
                    font-size: 1.3rem;
                }

                @include media(">tablet") {
                    font-size: 1.5rem;
                }

                &:after {
                    content: counter(i);
                    counter-increment: i;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 32px;
                    font-size: 1.4rem;
                    height: 32px;
                    margin-left: -16px;
                    top: 3px;
                    width: 32px;
                }
            }

            &._active {
                content: unset;

                &:before {
                    background-color: $button-primary__background;
                }

                span {
                    &:before {
                        background-color: $button-primary__background;
                    }
                }
            }
        }
    }

    .opc-estimated-wrapper {
        border-bottom: 0;
        margin: 0;
        padding: 0;

        @include media("<tablet") {
            display: block;
        }

        .estimated-block {
            display: none;
        }

        .minicart-wrapper {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 9;
            margin: 0;

            button {
                background-color: transparent;
                border: 0;
                height: 57px;
                position: relative;

                &:before {
                    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-cart2' viewBox='0 0 16 16'%3E%3Cpath d='M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l1.25 5h8.22l1.25-5H3.14zM5 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z'/%3E%3C/svg%3E");
                    //mask-image: url("data:image/svg+xml,%3Csvg width='28' height='25' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23000' fill-rule='nonzero'%3E%3Cpath d='M25.506 21.166a3.25 3.25 0 00-3.256-3.243 3.25 3.25 0 00-3.256 3.243 3.25 3.25 0 003.256 3.243 3.25 3.25 0 003.256-3.243zm-4.532 0a1.3 1.3 0 011.302-1.297 1.3 1.3 0 011.303 1.297 1.3 1.3 0 01-1.303 1.297 1.3 1.3 0 01-1.302-1.297zM26.724 4.992H7.612l-.651-2.647A2.277 2.277 0 004.744.604H1.276a.975.975 0 00-.976.973c0 .537.437.973.976.973H4.76c.154-.004.29.1.326.25l2.63 10.997a3.579 3.579 0 003.484 2.738h11.109a3.579 3.579 0 003.477-2.738l1.888-7.599a.97.97 0 00-.178-.833.978.978 0 00-.769-.373h-.003zM23.89 13.34a1.627 1.627 0 01-1.579 1.232H11.2a1.627 1.627 0 01-1.582-1.245L8.08 6.937h17.396L23.89 13.34zM10.46 24.396a3.25 3.25 0 003.257-3.243 3.25 3.25 0 00-3.256-3.243 3.25 3.25 0 00-3.256 3.243 3.25 3.25 0 003.256 3.243zm0-4.514a1.3 1.3 0 011.303 1.297 1.3 1.3 0 01-1.302 1.297 1.3 1.3 0 01-1.302-1.297 1.3 1.3 0 011.302-1.297z'/%3E%3C/g%3E%3C/svg%3E");
                    background-color: #000;
                    width: 30px;
                    height: 27px;
                    mask-size: contain;
                    mask-repeat: no-repeat;
                }

                .counter {
                    &.qty {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0;
                        border-radius: 100%;
                        min-width: 21px;
                        height: 21px;
                        position: absolute;
                        top: 5px;
                        right: 5px;
                        border: 2px solid #fff;
                        margin: 0;
                        font-size: 1.1rem;
                        background-color: $primary--light;
                    }
                }
            }
        }
    }

    .opc-sidebar {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        overflow-y: auto;
        margin-top: 0;
        margin-bottom: 0;

        @include media(">=tablet") {
            width: 450px;
            border-left: 1px solid #dee0e3;
            background-color: #fff;
        }

        @include media(">desktop") {
            width: 580px;
        }

        @include media("<tablet") {
            visibility: hidden;
            min-width: 0;
            transition: visibility 0s .3s,opacity .3s ease;
            opacity: 0;
        }

        &._show {
            opacity: 1;
            visibility: visible;
            transition: opacity .3s ease;
        }

        .modal-inner-wrap {
            background-color: #fff;
        }

        .modal-header {
            display: none;

            @include media("<=phone") {
                display: block;
                padding-bottom: 0;
            }

            .action-close {
                margin: 0;
            }
        }

        .modal-content {
            border-radius: 0;
            border: 0;

            @include media("<=phone") {
                margin-top: 0;
            }
        }

        .opc-block-summary {
            background-color: #fff;
            margin: 0;

            @include media("<=phone") {
                padding: 1.5rem;
            }

            @include media(">tablet") {
                padding: 3rem;
            }

            > .title {
                font-weight: 600;
                font-size: 1.9rem;
                border: 0;
            }

            .items-in-cart {
                border: 0;

                .title {
                    border: 0;

                    &:after {
                        width: 20px;
                    }

                    strong {
                        font-size: 1.4rem;
                        font-weight: 500;
                    }
                }

                .minicart-items {
                    padding: 0;
                    margin: 0;

                    .product-item {
                        .product {
                            .product-item-name-block {
                                padding-right: 1.5rem;
                            }

                            .product-item-details {
                                @include media("<=phone") {
                                    padding-left: 15px;
                                }
                            }

                            .product-item-inner {
                                background-color: transparent;
                            }

                            .subtotal {
                                .price-including-tax {
                                    .price {
                                        font-size: 1.4rem;
                                        font-weight: 500;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .table-totals {
                margin: 0 -4px;

                .mark {
                    background-color: transparent;
                }

                .totals {
                    &.incl {
                        strong {
                            font-weight: 600;

                            @include media(">phone") {
                                font-size: 1.6rem;
                            }

                            @include media(">tablet") {
                                font-size: 1.8rem;
                            }
                        }
                    }

                    &.excl {
                        display: none;
                    }

                    &.grand {
                        border-top: 1px solid $secondary--lighter;

                        th,
                        td {
                            padding-top: 1rem;
                        }
                    }
                }

                .totals-tax {
                    color: #a6a6a6;
                    font-size: 1.2rem;
                }
            }
        }

        .opc-block-shipping-information {
            .shipping-information-title {
                display: flex;
                align-items: center;
                justify-content: space-between;

                span {
                    font-weight: 600;
                    font-size: 1.7rem;
                    color: #333;
                }

                .action-edit {
                    position: relative;
                    line-height: 1;
                }
            }

            .shipping-information-content {
                a {
                    color: $black;
                }
            }
        }
    }

    .opc-wrapper {
        padding-right: 0;

        @include media(">phone") {
            width: 100%;
        }

        @include media(">tablet") {
            width: calc(100% - 450px);
        }

        @include media(">desktop") {
            width: calc(100% - 580px);
        }

        @media only screen and (min-width: 1400px) {
            width: calc(100% - 560px);
        }

        @media only screen and (min-width: 1600px) {
            width: calc(100% - 440px);
        }

        .step-content {
            margin: 0;

            .methods-shipping {
                .myparcel-delivery-options {
                    .myparcel-delivery-options__modal {
                        background-color: $secondary--lighter;
                        margin-top: 1.5rem;
                    }

                    h4 {
                        margin-top: 0;
                        margin-bottom: 1rem;
                    }

                    form {
                        label {
                            font-size: 1.3rem;
                        }

                        input {
                            height: 5.2rem;
                            border: 1px solid #d8d8d8;
                            padding: 0 1.6rem;
                            width: 100%;
                        }
                    }

                    &__choice {
                        .myparcel-delivery-options__float--right {
                            color: #7d879c;
                            font-size: 1.3rem;
                            font-weight: 500;
                        }

                        input {
                            &[type="radio"],
                            &[type="checkbox"]{
                                position: relative;
                                opacity: 1;
                                accent-color: $primary--light;
                            }
                        }
                    }
                }
            }
        }

        .form-login,
        .form-shipping-address {
            max-width: 100%;
            margin-top: 1rem;
            border: 0;
        }

        .form {
            .field {
                .control {
                    &._with-tooltip {
                        input {
                            margin-right: 0;
                            width: 100%;
                        }

                        .field-tooltip {
                            display: none;
                        }
                    }

                    span {
                        font-size: 1.2rem;
                    }
                }
            }
        }

        .opc {
            li {
                background-color: #fff;
                border: 1px solid $secondary--lighter;

                &#step_idin {
                    display: none !important;
                }

                &.checkout-shipping-method {
                    margin-top: 2rem;

                    @include media("<=phone") {
                        padding: 1.5rem;
                    }

                    .step-content {
                        margin: 0;
                    }
                }
            }

            .step-title {
                font-weight: 600;
                font-size: 1.9rem;
                padding: 0;
                border: 0;
            }

            .checkout-shipping-address {
                @include media("<=tablet") {
                    padding: 1.5rem;
                }

                @include media(">tablet") {
                    padding: 3rem;
                }

                .addresses {
                    margin-top: 1rem;

                    .shipping-address-item {
                        &.selected-item {
                            border-color: $secondary--dark;

                            &:after {
                                display: none;
                            }
                        }
                    }
                }
            }

            .checkout-billing-address {
                .billing-address-form {
                    max-width: unset;
                }
            }

            .checkout-shipping-address,
            .checkout-billing-address {
                .admin__control-fields {
                    padding: 0 !important;

                    legend {
                        display: none;
                    }

                    .control {
                        display: flex;
                        flex-wrap: wrap;

                        .field {
                            margin-bottom: 0;

                            &[name="shippingAddress.street.0"],
                            &[name="billingAddress.street.0"] {
                                width: 40%;
                            }

                            &[name="shippingAddress.street.1"],
                            &[name="shippingAddress.street.2"],
                            &[name="billingAddress.street.1"],
                            &[name="billingAddress.street.2"] {
                                width: 30%;
                            }

                            &._required {
                                label {
                                    &:after {
                                        content: '*';
                                        color: #e02b27;
                                        font-size: 1.2rem;
                                        margin: 0 0 0 5px;
                                    }
                                }
                            }



                            label {
                                &.label {
                                    position: relative;
                                    overflow: visible;
                                    width: auto;
                                    height: auto;
                                    margin: 0;
                                }
                            }
                        }
                    }
                }

                .address {
                    display: flex;
                    flex-flow: wrap;
                    margin: 0 -5px;

                    .field {
                        width: 100%;
                        padding: 0 5px;

                        &[name="shippingAddress.firstname"],
                        &[name="shippingAddress.lastname"],
                        &[name="shippingAddress.city"],
                        &[name="shippingAddress.postcode"],
                        &[name="shippingAddress.telephone"],
                        &[name="shippingAddress.country_id"] {
                            width: 50%;
                        }

                        select {
                            height: 5.2rem;
                            border: 1px solid #d8d8d8;
                            border-radius: .2rem;

                            &:focus {
                                box-shadow: none;
                            }
                        }

                        &._warn {
                            .message {
                                margin-bottom: 0;
                                padding: 1rem !important;

                                span {
                                    line-height: 1.7;

                                    &:before {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            #opc-shipping_method {
                @include media(">phone") {
                    padding: 1.5rem;
                }

                @include media(">tablet") {
                    padding: 3rem;
                }

                .table-checkout-shipping-method {
                    margin-top: 1.5rem;
                    margin-bottom: 1rem;
                    width: 100%;

                    tbody {
                        tr {
                            border: 1px solid #dee0e3;
                            padding: 1rem 1.5rem;
                            cursor: pointer;

                            &:not(:first-child) {
                                margin-top: 1rem;
                            }

                            &.row {
                                margin-left: 0;
                                margin-right: 0;
                            }

                            td {
                                padding-left: 0;
                                padding-right: 0;
                                padding-bottom: 10px;
                                padding-top: 10px;
                                border: 0;

                                &.col-carrier {
                                    font-weight: 600;
                                    white-space: nowrap;
                                }

                                &.col-method {
                                    input {
                                        accent-color: $primary--dark;
                                    }
                                }
                            }
                        }
                    }
                }

                #shipping-method-buttons-container {
                    justify-content: flex-end;

                    .primary {
                        width: auto !important;

                        .button {
                            margin-top: 0;
                            border-radius: 0;
                            background-color: $primary--light;
                            border-color: $primary--light;
                            color: $black;
                        }
                    }
                }
            }

            #payment {
                @include media("<=phone") {
                    padding: 1.5rem;
                }

                @include media(">tablet") {
                    padding: 3rem;
                }

                .payments {
                    .opc-payment {
                        margin-bottom: 1.5rem;
                    }

                    #recaptcha-checkout-place-order-wrapper {
                        display: none;
                    }

                    .fieldset {
                        br {
                            display: none;
                        }

                        hr {
                            display: none;
                        }
                    }

                    .payment-methods {
                        margin-top: 1.5rem;

                        @include media("<=phone") {
                            margin: 0;
                        }

                        @include media(">phone", "<=tablet") {
                            margin-left: 0;
                            margin-right: 0;
                        }

                        .payment-method {
                            border: 1px solid transparent;

                            &._active {
                                background-color: rgba($secondary--dark, 0.1);
                                border: 1px solid rgba($secondary--dark, 0.5);
                            }

                            &:not(:first-child) {
                                margin-top: 1rem;
                            }

                            .payment-method-title {
                                margin-bottom: 0;
                                padding: 1rem;
                                border: 1px solid $secondary--lighter;
                                cursor: pointer;

                                input {
                                    accent-color: $primary--light;
                                }

                                &.choice {
                                    display: flex;
                                }

                                .label {
                                    margin: 0;
                                    margin-left: 1rem;

                                    img {
                                        margin-right: 1rem;
                                    }

                                    span {
                                        font-weight: 600;
                                    }
                                }
                            }

                            .payment-method-content {
                                padding: 1rem;

                                .field-select-billing {
                                    max-width: 100%;
                                }

                                .bank-types {
                                    input {
                                        margin-top: 0;
                                        margin-right: 1rem;
                                        accent-color: $primary--light;
                                    }
                                }

                                .payment-method-billing-address {
                                    background: #f9f9f9;
                                    border: 1px solid #eee;
                                    padding: 1rem;
                                    margin-top: 1rem;
                                    display: none;

                                    .checkout-billing-address {
                                        margin: 0;

                                        .billing-address-same-as-shipping-block {
                                            input {
                                                &[type="checkbox"] {
                                                    top: 0;
                                                }
                                            }
                                        }

                                        .billing-address-details {
                                            padding-left: 0;
                                        }

                                        .actions-toolbar {
                                            .primary {
                                                .action-cancel {
                                                    background-color: transparent;
                                                    font-weight: 500;
                                                    color: $black;
                                                }

                                                .action-update {
                                                    color: $white;
                                                    border-radius: 0.25rem;
                                                }
                                            }
                                        }
                                    }
                                }

                                .checkout-agreements-block {
                                    margin-top: 1rem;

                                    .checkout-agreements {
                                        .checkout-agreement {
                                            display: block;

                                            input {
                                                &[type="checkbox"] {
                                                    top: 0;
                                                    margin-top: 0;
                                                }
                                            }

                                            label  {
                                                margin-bottom: 0;

                                                button {
                                                    color: #000;
                                                    font-size: 1.2rem;
                                                }
                                            }
                                        }
                                    }
                                }

                                .actions-toolbar {
                                    .primary {
                                        margin-left: auto;

                                        button {
                                            background-color: $button-primary__background;
                                            border-color: $button-primary__background;

                                            &:hover {
                                                background-color: $button-primary__hover__background;
                                                border-color: $button-primary__hover__background;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .discount-code {
                        margin: 0;

                        .payment-option-title {
                            border-top: 0;
                            margin-top: 2rem;
                            margin-bottom: 0;
                            padding: 0;

                            #block-discount-heading {
                                color: #000;
                            }
                        }

                        .payment-option-content {
                            padding: 2rem;
                            margin-top: 1rem;
                            background-color: $secondary--lighter;

                            #discount-form {
                                max-width: unset;
                            }
                        }
                    }
                }
            }
        }
    }
}
