@mixin visible-for-screenreader() {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@mixin clearfix() {
  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }
}
