.login-container {

    @include media("<phone") {
        padding: 30px 15px;
    }

    .action.remind {
        font-family: $font-family__sans-serif--bold;
    }
    
    .actions-toolbar {
        @include media(">=phone") {
            margin-left: 0 !important;
        }
    }

    .fieldset {
        &::after {
            color: #FA8200;
            font-family: $font-family__sans-serif;
            font-size: 14px;
            line-height: 16px;
        }
    }
}