.sections.nav-sections {
    margin-bottom: 0;
    margin-left: auto;
    transition: left .25s, width .35s;
    border-top: 1px solid #0F1112;

    @include media("<=phone") {
        top: 52px;
        background: #1B1D1D;
        width: 0;
        z-index: 11;
    }

    &.open {
        left: 0;
        width: 100%;
    }
}

.nav-sections,
.navigation {
    background: transparent;
}

.nav-sections {
    @include media(">=phone") {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 70%;
        padding-left: 4rem;
    }
}

.navigation {
    @include media("<=phone") {
        padding: 0;
        max-width: 100%;
    }

    ul {
        margin: 0;
    }

    .main-menu {
        display: none;
        padding: 0 15px;

        @include media("<=phone") {
            margin-top: 1.5rem;
            display: block;
        }

        h4 {
            color: #8b8b8c;
            margin-top: 2rem;
            margin-bottom: 1rem;
        }

        ul {
            li {
                margin: 0;

                a {
                    padding: 0;
                }
            }
        }
    }
}

.nav-sections-item-title {
    display: none;
}

.nav-sections-item-content {
    margin-top: 0;
    padding: 0;

    @include media("<=phone") {
        margin-top: 2rem;
    }
}

.navigation .level0 {
    @include media("<=desktop") {
        display: inline-block;
        position: relative;
        margin: 0;
    }

    @include media("<=phone") {
        display: block;
    }
}

.navigation .level0.active > .level-top,
.navigation .level0.has-active > .level-top {
    @include media("<=tablet", ">phone") {
        border-bottom: 2px solid $secondary--dark;
    }
}

.navigation .level0 > .level-top,
.navigation li.level0 {
    color: #8b8b8c;
    font-family: $font-family__title;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    margin-right: 0 !important;

    span {
        position: relative;
        transition: 0.3s ease;

        &:after {
            content: "";
            height: 2px;
            width: 0;
            background: $primary--light;
            background: linear-gradient(80deg, rgba($primary--light, 0.9) 0%, rgba($primary--light, 0) 100%);
            position: absolute;
            left: 0;
            bottom: -5px;
            transition: 0.3s ease;
        }
    }

    &:last-child {
        margin: 0;
    }

    &:hover,
    &.active {
        span {
            color: $white;

            &:after {
                width: 100%;
            }
        }
    }

    @include media("<=tablet", ">phone") {
        padding: 0 2px;
        font-size: 14px;
    }
    @include media("<=phone") {
        &,
        &:last-child {
            border: none;

            a {
                padding: 5px 15px;
            }
        }
    }

    a {
        @include media("<=phone") {
            border: none !important;

            span {
                margin-left: 0;
            }
        }
    }
}

.navigation ul {
    padding: 0;

    &:nth-child(2) {
        display: none;
    }

    @include media("<=tablet") {
        margin-top: 0;
    }

    @include media("<=phone") {
        margin-top: 0;
        &:nth-child(2) {
            display: block;
        }
    }

    li.level0 {
        span {
            @include media("<=phone") {
                margin: 0 !important;
            }
        }
    }
}

.nav-toggle {
    cursor: pointer;
    display: none;
    height: 40px;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    width: 25px;
    position: unset;

    @include media("<=phone") {
        display: block;
        margin-right: auto;
        height: 18px;
    }

    &:before {
        content: '';
        display: none;
    }

    span {
        background: $white;
        border-radius: 0;
        display: block;
        height: 3px;
        opacity: 1;
        position: relative;
        width: 100%;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;

        &:nth-child(1) {
            @include visible-for-screenreader();
        }

        &:nth-child(2) {
            top: 0;
        }

        &:nth-child(3) {
            top: 6px;
        }

        &:nth-child(4) {
            top: 12px;
        }
    }

    &.open span {
        &:nth-child(2) {
            top: 10px;
            transform: rotate(135deg);
        }

        &:nth-child(3) {
            opacity: 0;
        }

        &:nth-child(4) {
            top: 6px;
            transform: rotate(-135deg);
        }
    }
}

.navigation .level0.active > .level-top {
    border: none;

    span {
        border: none;
    }
}

.my-account {
    display: block;
    height: 22px;
    width: 22px;

    a {
        &::before {
            content: "";
            width: 22px;
            height: 22px;
            position: absolute;
            background-image: url("../svg/favour-user.svg");
            background-repeat: no-repeat;
            background-size: 16px;
            background-position: center;
            top: 61%;
            right: -6px;
            transform: translateY(-50%);
        }
    }
}
