body {
    &.customer-account-forgotpassword,
    &.customer-account-create {
        .page-main {
            padding: 5rem 0;

            .page-title-wrapper {
                text-align: center;
            }

            form {
                margin: 0 auto;
            }
        }
    }

    &.customer-account-login,
    &.customer-account-forgotpassword {
        .page-title-wrapper {
            display: none;
        }
    }

    &[class*=customer-] {
        .block-title {
            h2 {
                font-weight: 700;
                text-transform: uppercase;
                margin-bottom: 0;
            }
        }

        .fieldset > .field:not(.choice) > .label {
            text-align: left;
        }

        button[type=submit] {
            outline: none;
            appearance: none;
            background-color: $primary--light;
            color: $black;
            border-color: $primary--light;
            border-radius: 0;
            padding: 1.5rem 2.5rem;

            &:hover {
                color: #0F1633;
                background-color: #F2DF00;
                border: 1px solid #F2DF00;
            }
        }

        .action.create {
            border: 2px solid #1A2021;
            background-color: #fff;
            outline: none;
            appearance: none;
            color: #1A2021;
            border-radius: 0;
            padding: 1rem 2rem;

            &:hover {
                background-color: #1A2021;
                color: $primary--light;
            }
        }

        .action.back {
            border-radius: 0;
            appearance: none;
            font-size: 1.4rem;
            font-weight: 400;
            margin: 0;
            text-decoration: none;
            display: flex;
            align-items: center;
            position: relative;

            &::before {
                -webkit-font-smoothing: antialiased;
                font-size: 32px;
                line-height: 16px;
                color: inherit;
                content: "\E617";
                font-family: icons-blank-theme;
                margin: 0;
                display: inline-block;
                font-weight: 400;
                overflow: hidden;
                speak: none;
                text-align: center;
            }
        }
    }
}
