.minicart-wrapper {
    order: 3;
    display: flex;
    align-items: center;
    margin-left: 1.5rem;
    margin-top: 0;

    @include media("<=phone") {
        margin-left: 1rem;
    }

    .action.showcart {
        margin-top: 0;

        &:before {
            content: "";
            margin-top: 0;
            mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-cart2' viewBox='0 0 16 16'%3E%3Cpath d='M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l1.25 5h8.22l1.25-5H3.14zM5 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z'/%3E%3C/svg%3E");
            width: 26px;
            height: 28px;
            mask-size: contain;
            mask-repeat: no-repeat;
            background-color: #fff;
            text-indent: -99999px;
        }

        .counter {
            &.qty {
                top: -5px;
                right: -10px;
                font-weight: 600;
                background-color: $primary--light;
                z-index: 1;
            }
        }
    }

    .block-minicart {
        margin-top: 1rem;
        padding: 1.5rem;

        &:before,
        &:after {
            right: 20px;

            @include media("<=phone") {
                right: 5px;
            }
        }

        @include media("<phone") {
            width: 320px;
        }
    }

    .action-delete {
        float: none;
        margin: 0;
        position: absolute;
        bottom: 0;
        right: 0;
        width: auto;
    }
}
