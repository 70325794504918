.checkout-onepage-success {
    .page-top-wrapper {
        display: none;
    }

    .page-main {
        padding: 5rem 0;

        .page-title-wrapper {
            .page-title {
                margin-bottom: 3rem;
            }
        }
    }
}

.success-page {
    display: flex;
    flex-direction: column;
    padding: 80px 0;

    @media screen and (max-width: 539px) {
        padding: 40px 0 0px;
    }

    // Hide according to design --> customers are now not able to register an account
    + #registration {
        display: none;
    }

    header {
        text-align: center;

        h1 {
            font-size: 44px;
            color: $black;
            line-height: 50px;
            margin: 0 0 8px;
        }

        h3 {
            font-size: 22px;
            line-height: 25px;
            color: rgba(#303030, 0.5);
            margin-bottom: 40px;
            padding: 0;

            &:before {
                display: none;
            }
        }
    }

    .success__main {
        background-color: $secondary--light;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .success-blocks {
        display: flex;
        text-align: center;
        flex-wrap: wrap;
        width: 100%;
        position: relative;
        @media screen and (min-width: 992px) {
            max-width: 1140px;
        }

        &::before {
            content: '';
            height: 0;
            border: 1px dashed $black;
            display: block;
            position: absolute;
            width: 90%;
            top: 65px;
            z-index: 1;
            left: 50%;
            transform: translateX(-50%);
            @media screen and (max-width: 539px) {
                height: 90%;
                width: 0;
                top: 0;
            }
        }
    }

    .success-block {
        display: flex;
        flex-direction: column;
        background-color: white;
        padding: 40px;
        position: relative;
        z-index: 2;
        margin-bottom: 0;

        @media screen and (min-width: 768px) and (max-width: 991px) {
            lost-column: 1/3 3 15px flex;
        }
        @media screen and (min-width: 992px) {
            lost-column: 1/3 3 30px flex;
        }
        @media screen and (max-width: 767px) {
            padding: 20px;
            lost-column: 1/3 3 15px flex;
        }
        @media screen and (max-width: 539px) {
            padding: 20px;
            lost-column: 1/1 1 0 flex;
            margin-bottom: 15px;
        }

        &__icon {
            margin: -5px 0 0;
        }

        &__title {
            font-size: 17px;
            color: $black;
            line-height: 22px;
            margin: 0;
            padding: 0;

            &:before {
                display: none;
            }
        }

        &__description {
            font-size: 14px;
            line-height: 22px;
            color: rgba($black, 0.5);
        }
    }
}

.success-aside {
    text-align: center;
    padding: 60px 20px;

    @media screen and (max-width: 539px) {
        padding: 40px 20px;
    }

    &__title {
        font-size: 34px;
        color: $black;
        line-height: 40px;
        margin: 0 0 25px;
    }

    &__description {
        font-size: 14px;
        line-height: 22px;
        color: rgba(#303030, 0.5);
        max-width: 725px;
        margin: 0 auto 30px;
        display: block;

        strong {
            color: #000;
            font-weight: normal;
            font-family: "HelveticaNeue";

            @media screen and (max-width: 767px) {
                display: block;
            }
        }
    }

    &__button {
        margin-bottom: 15px;
        width: 250px;

        &:hover {
            text-decoration: none;
            color: white;
        }
    }

    &__subtext {
        display: block;
        color: rgba(#303030, 0.5);
        font-size: 14px;
        line-height: 22px;
        margin: 0;
    }
}

.success-aside__button {
    border: 2px solid $primary--light_dark;
    padding: 10px;
    margin-bottom: 10px;

    span {
        color: $primary--light_dark;
        font-family: $font-family__title;
    }

    &:hover {
        background: $primary--light_dark;

        span {
            color: $black;
        }
    }
}

.success-aside__subtext {
    margin-top: 20px;
}
