.main-title-additional {
    float: left;
    line-height: 22px;
    margin-right: 5px;

    span {
        font-family: $font-family__title;
        font-size: 18px;

        &:after {
            content: '/';
            font-family: $font-family__sans-serif;
            margin: 0 5px;
        }

        &:last-of-type:after {
            content: '|';
        }
    }
}

.product-reviews-summary {
    margin: 0;
    position: relative;

    .rating-result {
        left: 0;
        position: absolute;
    }
}

ol.review-items {
    position: relative;

    &:not(.all) li:nth-child(n+3):not(.review-items--toolbar) {
        display: none;
    }

    li.review-items--toolbar {
        background: linear-gradient(to bottom, rgba($white, 0) 0%, rgba($white, .75) 35%, white 55%);
        bottom: 0;
        padding-top: 80px;
        position: absolute;
        width: 100%;
    }

    &.all li.review-items--toolbar {
        padding-top: 50px;
        position: relative;
    }
}

.review-ratings ~ .review-details {
    margin: 0;
    clear: both;
    color: #7d879c;
}

.review-ratings ~ .review-content {
    margin: 10px 0;
}

.review-author, .review-date {
    display: inline;
    margin: 0;
}

.review-title {
    display: block;
    float: left;
    font-weight: 600;
    margin-bottom: 10px;
    margin-left: 100px;
    padding-top: 4px;
    @include media('<=phone') {
        margin-left: 0;
    }
}

.review-date .review-details-label {
    text-transform: lowercase;
}

.review-item {
    border-bottom: 2px solid $secondary--light;

    &:first-child {
        padding-top: 0;
    }
}

.rating-summary {
    svg {
        &:not(:first-of-type) {
            margin-left: -2px;
        }
    }
}

.review-control-vote svg,
.review-control-vote label svg,
.rating-summary svg {
    fill: rgba($black, .2);
    height: 16px;
    width: 16px;
    margin-right: 0;

    &.rating--active {
        fill: $secondary--green;
    }
}

.rating-summary .rating-result {
    margin-left: 0;
    width: 107px;
}

.rating-summary .rating-result:before,
.rating-summary .rating-result > span:before {
    content: '';
    display: none;
}

.rating-summary .rating-result > span {
    position: absolute;
    top: 0;
}

.fieldset .review-legend.legend {
    span {
        float: left;
    }

    strong {
        float: left;
        margin-left: 10px;
    }
}

.review-field-ratings {
    .review-field-rating label.label,
    legend.label span,
    legend.label:after {
        content: '';
        display: none;
    }
}

.review-control-vote {
    position: relative;

    &:before {
        content: '';
        display: none;
    }
}

@for $i from 1 through 5 {
    .review-control-vote .rating-#{$i} {
        position: absolute;
        top: 2px;
        width: calc(22px * #{$i});
        height: 22px;
    }

    .review-control-vote .rating-#{$i}:before {
        content: '';
        display: none;
    }
    .review-control-vote .rating-#{$i} svg {
        display: none;
    }
    .review-control-vote input[type="radio"]:focus + .rating-#{$i} svg,
    .review-control-vote input[type="radio"]:checked + .rating-#{$i} svg {
        display: inline;
    }
    .review-control-vote .rating-#{$i}:hover svg {
        display: inline;
    }
}

.block.review-add {
    &.show {
        display: block;
    }
}

a.post-review {
    background: transparent;
    border: none;
    cursor: pointer;
    margin-left: 20px;

    svg {
        height: 10px;
        width: 6px;
        position: relative;
        left: 10px;
        top: 1px;
    }
}

.block.review-add-form {
    @include media('<=phone') {
        margin-top: 20px;
    }
}

.review-form {
    max-width: 600px;
}

.product-reviews-summary .reviews-actions {
    line-height: 22px;
}

.rating-count {
    margin-top: -3px;
    text-transform: lowercase;
    margin-left: 0.5rem;
    font-size: 1.2rem;
}
