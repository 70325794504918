div.ui-tooltip {
  max-width: 400px;
}

.field-tooltip .field-tooltip-action {
  margin: 4px 4px 0 0;
}

.ui-tooltip.ui-widget {
  box-shadow: none;
  padding: 0;
  .ui-tooltip-content {
    background-color: aquamarine;
    box-shadow: 0 0 1px 1px rgba($black, 0.2);
    color: red;
    line-height: 24px;
    padding: 8px 20px;
  }
  .arrow:after {
    box-shadow: none;
  }
}

.ui-tooltip,
.arrow:after {
  background-color: yellow;
  border: none;
}
