.page-wrapper--fullwidth {
    @include media(">=tablet") {
        background: linear-gradient(to right, $white 50%, $secondary--lighter 51%);
    }

    margin-bottom: 25px;
}


.catalog-product-view {
    .page-main {
        padding-top: 0;
        max-width: 100%;

        .column.main {
            display: flex;
            flex-wrap: wrap;

            .product-info-main {
                order: 2;
            }

            .product.media {
                order: 1;
            }

            .product-info--descriptionbar {
                order: 3;
            }

            .product.attribute.description {
                order: 4;
                width: 100%;
            }

            #downloads {
                order: 5;
            }

            .product-info--wrapper {
                order: 6;
            }
        }
    }
}


.page-wrapper--container {
    @include media(">=tablet") {
        @include container-size;
        width: 100%;
    }

    overflow-x: hidden;
    position: relative;

    &:after {
        @include media(">=tablet") {
            bottom: 0;
            border-color: $white transparent transparent transparent;
            border-style: solid;
            border-width: 1480px 200px 0 0;
            content: '';
            height: 0;
            left: 50%;
            position: absolute;
            width: 0;
        }
    }
}

.page-layout-1column .product-info--responsive-media,
.product-info--additional-reviews {
    @include container-size();
    width: 100%;

    @include media("<=tablet") {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.product-info--responsive {
    @include media("<=tablet") {
        display: flex;
        flex-direction: column;
    }
}

.product-info-main .product.attribute.overview {
    margin: 40px 0 0;

    @include media("<=phone") {
        margin: 20px 0 0;
    }
}

.product-info-main {
    .alert {
        color: #000;
    }

    .page-title-wrapper {
        .page-title {
            text-transform: uppercase;
            margin-top: 0;
            line-height: 1.2;
            font-weight: 700;
            font-size: 3.5rem;
            margin-bottom: 0;

            @include media("<=phone") {
                font-size: 2rem;
            }
        }
    }

    .product-info-price {
        margin-bottom: 2rem;

        @include media("<=phone") {
            margin-bottom: 1rem;
        }

        .price-box {
            margin-top: 3rem;
            margin-left: -4px;
            display: flex;
            flex-direction: column;

            @include media("<=phone") {
                margin-top: 1rem;
            }

            .price-container {
                line-height: inherit;

                span.price {
                    color: #343e40;
                    font-weight: 700;
                    font-size: 2.8rem;

                    @include media("<=phone") {
                        font-size: 2rem;
                    }
                }
            }

            .special-price,
            .normal-price {
                order: 2;

                span.price {
                    margin-left: -4px;
                }
            }

            .old-price {
                order: 1;

                span.price {
                    font-size: 1.8rem;
                    color: #7d879c;
                    font-weight: 500;
                }
            }
        }
    }

    .product-add-form {
        .swatch-opt {
            @include media("<=phone") {
                margin: 1rem 0;
            }
        }
    }

    .overview-additional {
        ul {
            margin: 0;
            padding-left: 12px;

            @include media("<=phone") {
                line-height: 1.7;
            }

            li {
                margin: 0;
                color: #373f50;

                @include media("<=phone") {
                    font-size: 14px;
                }
            }
        }
    }

    .product-options-wrapper {
        .swatch-attribute-label {
            display: none;
        }

        .swatch-attribute-selected-option {
            display: none;
        }

        .swatch-option {
            width: 30px;
            height: 30px;
            border-radius: 100%;
            margin: 0 5px 5px 0;

            &.selected,
            &:hover {
                outline-color: $black;
            }
        }

        .swatch-attribute {
            .mage-error {

            }
        }
    }

    .box-tocart {
        .fieldset {
            @include media("<=phone") {
                display: flex;
            }

            .qty {
                padding: 0;
                margin: 0;

                label {
                    display: none;
                }

                input {
                    border: 0;
                }
            }

            .actions {
                .tocart {
                    padding: 1rem 5rem !important;
                    height: 52px;
                    border-radius: 0;
                    font-size: 1.6rem;

                    &:hover {
                        background-color: $primary--light__hover !important;
                    }
                }
            }
        }
    }
}

.product-info--responsive-media {
    @include media("<=tablet") {
        order: -1;
    }
}

.page-layout-1column .product-info-main,
.page-layout-1column .product.media {
    width: 100%;

    .loading-mask {
        display: none;
    }
}

.page-layout-1column .product.media {
    @include media(">=tablet") {
        max-width: 50%;
        width: 50%;
    }

    @include media(">=large") {
        max-width: 60%;
        width: 60%;
    }

    @each $name, $breakpoint in $breakpoints {
        @include media(">=#{$name}") {
            padding-left: calc(((100vw - #{map-get($breakpoints,  #{$name})})) / 2);
        }
    }

    .gallery-placeholder {
        text-align: center;
        position: sticky;
        top: 0;

        @include media(">=tablet") {
            padding: 5rem 0;
        }

        img {
            position: unset;
            transform: unset;
        }
    }
}

.page-layout-1column .product-info-main {
    background-color: $secondary--lighter;
    position: relative;
    padding: 5rem;
    max-width: unset !important;

    @include media("<=phone") {
        padding: 15px;
    }

    @each $name, $breakpoint in $breakpoints {
        @include media(">=#{$name}") {
            padding-right: calc(((100vw - #{map-get($breakpoints,  #{$name})})) / 2);
        }
    }

    @include media("<=tablet") {
        @include container-size();
        float: inherit;
        width: 100%;
    }

    @include media(">=tablet") {
        max-width: 50%;
        width: 50%;
    }

    @include media(">=large") {
        max-width: 40%;
        width: 40%;
    }
}

.product-info--responsive-main {
    @include media("<=tablet") {
        background-color: $secondary--lighter;
        margin-top: 50px;
        overflow: hidden;
        padding: 50px 25px 20px 25px;
        position: relative;

        &:before {
            border-color: $white transparent transparent transparent;
            border-style: solid;
            border-width: 30px 991px 0 0;
            content: '';
            height: 0;
            left: 0;
            position: absolute;
            top: 0;
            width: 0;
        }
    }

    @include media("<=phone") {
        margin-top: 0;
        padding: 50px 15px 20px 15px;
    }
}

.page-layout-1column .product.media {
    margin-bottom: 0;
}

.main-title-additional {
    float: left;
    line-height: 22px;
    margin-right: 5px;

    span {
        font-family: $font-family__title;
        font-size: 18px;

        &:after {
            content: '/';
            font-family: $font-family__sans-serif;
            margin: 0 5px;
        }

        &:last-of-type:after {
            content: '|';
        }
    }
}

.overview--pdpusps {
    margin-top: 3.5rem;

    @include media("<=tablet") {
        border: none;
        margin-top: 20px;
        padding: 20px 0 0;
    }

    p {
        font-family: $font-family__title;
        margin: 0;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        &, li {
            position: relative;
            font-size: 1.3rem;
            line-height: 1.7;
            margin-left: 1rem;
            margin-bottom: 0.4rem;
            color: $secondary--green;

            a {
                color: $secondary--green;
            }
        }

        svg {
            fill: $secondary--green;
            height: 8px;
            left: -20px;
            margin-right: 10px;
            position: absolute;
            top: 7px;
            width: 12px;
        }
    }
}

.product-info--wrapper {
    clear: both;
    display: block;
    width: 100%;
    padding-bottom: 5rem;

    @include media("<=tablet") {
        margin-top: 0;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 10px;
    }

    @include media("<=phone") {
        padding-top: 0;
    }

    .block.review-list {
        margin-bottom: 0;
        padding-bottom: 0;
        border: 0;
    }

    .specifications-wrapper {
        .product-image-container {
            .product-image-wrapper {
                padding-bottom: 100% !important;
            }
        }
    }
}

.product-info--descriptionbar {
    background: $white;
    border-top: 1px solid $default-border-color;
    border-bottom: 1px solid $default-border-color;
    width: 100%;
    position: sticky;
    z-index: 2;

    @include media('<=phone') {
        display: none;
    }

    ul {
        @include container-size;
        display: flex;
        justify-content: center;
        padding: 0;
        list-style-type: none;

        li {
            font-family: $font-family__title;
            font-size: 14px;
            padding-right: 60px;
            margin: 0;

            @include media('<=tablet') {
                padding-right: 2.5rem;
            }

            a {
                padding: 1.5rem 0;
                display: block;
                color: #7d879c;
                font-weight: 600;

                &.active {
                    color: $black;
                }
            }
        }
    }
}

.product-description--wrapper {
    @include clearfix();

    .product.attribute.description {
        width: calc(100% * 8 / 12 - 33px);

        &:nth-child(n) {
            float: left;
            margin-right: 99px;
            clear: none;
        }

        @include media("<=phone") {
            padding-bottom: 20px;
        }
    }
}

.block.review-list,
.block.review-add,
.product-info--features,
.specifications-wrapper .inner,
.product-attachment--wrapper,
.block.upsell,
#downloads .am-attachments {
    @include container-size;

    h3 {
        margin-bottom: 1.5rem;
        font-size: 2.2rem;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 3rem;

        @include media("<=phone") {
            margin-bottom: 1.5rem;
        }
    }

    border-bottom: 1px solid $default-border-color;
    margin-bottom: 5rem;
    padding-bottom: 5rem;

    @include media("<=phone") {
        margin-bottom: 1.5rem;
        padding-bottom: 1.55rem;
    }
}

#description {
    margin-bottom: 5rem;

    @include media("<=phone") {
        margin-bottom: 2.5rem;
    }
}

#downloads {
    width: 100%;

    @include media("<=phone") {
        padding-left: 15px;
        padding-right: 15px;
    }

    .am-attachments {
        .am-fileline {
            .am-fileicon {
                display: none;
            }

            .am-filelink {
                color: #000;
            }

            .am-filesize {
                font-weight: 600;
            }
        }
    }
}

.review-add-form {
    @include container-size;
}

.product-info--features {
    ul {
        column-count: 3;
        list-style-type: none;
        margin: 0;
        @include media("<=phone") {
            column-count: 1;
        }

        li {
            display: inline-block;
            min-height: 42px;

            @include media("<=phone") {
                min-height: 37px;
            }

            margin: 0;
            padding: 0 0 0 42px;
            position: relative;
            width: 100%;

            svg {
                color: $white;
                fill: $black;
                height: 32px;
                left: 0;
                position: absolute;
                top: 0;
                width: 32px;
            }
        }
    }
}

.product-info--responsive {
    margin-top: 50px;
}

.specifications-wrapper {
    .additional-attributes-wrapper {
        margin: 0;

        .table.additional-attributes {
            > tbody > tr > th {
                width: auto;
            }
        }
    }

    .table.additional-attributes > tbody > tr:first-of-type th,
    .table.additional-attributes > tbody > tr:first-of-type td {
        padding-top: 0;
    }

    .table.additional-attributes > tbody > tr:last-of-type th,
    .table.additional-attributes > tbody > tr:last-of-type td {
        padding-bottom: 0;
    }

    .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr td:before,
    .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr th:before {
        color: #fff;
        display: block;
        font-family: $font-family__title;
        font-weight: 600;
        padding-left: 0;
    }

    .table.additional-attributes tbody tr td {
        @include media("<=phone") {
            display: flex !important;
            flex-wrap: wrap !important;
            font-size: 14px;
        }
    }

    .table.additional-attributes > tbody > tr > th {
        font-family: $font-family__title;
        font-weight: 400;
        width: 20%;
        padding-left: 0;
    }

    .table.additional-attributes > tbody > tr > td {
        padding-left: 30px;
    }

    .additional-attributes--imageincl {
        display: flex;
        justify-content: space-between;
    }

    .additional-attributes--image {
        @include media("<=tablet") {
            display: none;
        }
    }

    .additional-attributes--text {
        @include media("<=phone") {
            margin: 0 -15px;
        }

        table {
            margin-bottom: 10px;
            height: auto !important;

            @include media("<=phone") {
                display: block;
                overflow-x: auto;
                white-space: nowrap;
            }
        }

        table tbody tr:first-of-type td:not(:first-child) {
            height: 50px;
            max-height: 50px;
            padding: 0px 10px;
        }

        table tbody tr td {
            border-bottom: 2px solid $secondary--lighter;
            text-align: center;
            width: 20%;
            height: 50px;
            max-height: 50px;

            &:first-of-type {
                background: $secondary--lighter;
                font-family: $font-family__title;
                padding-left: 15px;
                text-align: left;
            }
        }

        p {
            font-size: 13px;
            line-height: 20px;

            @include media("<=phone") {
                padding: 0 15px;
            }
        }
    }
}

#specifications {
    background: $black;

    @include media("<=tablet") {
        margin: 0 -15px;
        padding: 2.5rem 20px;
    }

    @include media(">=tablet") {
        margin-bottom: 5rem;
        padding: 5rem 0;
    }

    .inner {
        border-bottom: 0;
        padding: 0;

        .d-flex {
            @include media(">=tablet") {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }
        }

        .col-6 {
            @include media(">=tablet") {
                width: 50%;
            }
        }

        .contact-block {
            @include media(">=tablet") {
                padding-left: 5rem;
            }

            h4 {
                color: #fff;
                position: relative;
                font-size: 2.5rem;
                padding-right: 12rem;

                &:after {
                    content: "";
                    height: 2px;
                    width: 30%;
                    background: #F7D000;
                    background: linear-gradient(80deg, rgba(247, 208, 0, 0.9) 0%, rgba(247, 208, 0, 0) 100%);
                    position: absolute;
                    left: 0;
                    bottom: -10px;
                    transition: 0.3s ease;
                }
            }

            &__details {
                margin-top: 5.5rem;
                display: flex;

                a {
                    display: flex;
                    align-items: center;
                    color: #fff;
                    margin-right: 4rem;
                    font-size: 1.8rem;

                    svg {
                        width: 25px;
                        height: 25px;
                        margin-right: 1.5rem;
                    }
                }
            }
        }

        h3 {
            color: #fff;
        }

        .table-wrapper {
            width: 100%;
        }

        .additional-attributes {
            border-collapse: collapse;
            width: 100%;

            tbody {
                tr {
                    color: #fff;

                    th,
                    td {
                        padding: 0.5rem !important;
                    }

                    &:nth-child(odd) {
                        background-color: #2a2b2b;
                    }
                }
            }
        }
    }
}

.catalog-category-view .page-main .columns {
    position: static;
}

body.catalog-category-view .page-bottom {
    max-width: 100%;

    @include media("<=phone") {
        padding: 20px 0 0 0;
    }

    h3 + * {
        font-size: 18px;
        padding-left: 50px;
    }

    .category-cms {
        overflow: hidden;
        position: relative;

        &:before {
            @include media(">phone") {
                bottom: 0;
                border-color: $white transparent transparent transparent;
                border-style: solid;
                border-width: 1480px 200px 0 0;
                content: '';
                height: 0;
                left: 49.9%;
                position: absolute;
                width: 0;
            }
        }

        > div {
            position: relative;
            z-index: 2;
        }
    }

    button {
        display: block;
    }

    button svg {
        height: 7px;
        position: relative;
        right: -10px;
        top: -1px;
        width: 10px;
    }
}

.category_banner-bottom {
    margin-top: 0;
}

.categorypage__usps {
    padding: 0;
    width: 100%;
    background: white;

    .columns-1 {
        margin-top: 0;
    }

    @include media("<=phone") {
        display: none;
    }

    ul {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin: 0;

        li {
            display: flex;
            flex-direction: row-reverse;
            font-size: 14px;
            text-transform: uppercase;

            &:before {
                display: none;
            }

            svg {
                height: 7px;
                margin: 0 10px 0 0;
                position: relative;
                top: 10px;
                width: 10px;
            }
        }
    }
}

#product-review-container,
.specifications-wrapper,
.features-extra-container,
.product-description--wrapper {

    &.open {
        max-height: 3000px;
        transition: max-height 0.15s ease-in;

        &:after {
            display: none;
        }
    }
}

#features {
    &.product-info--features {
        padding-bottom: 0;
        border: 0;
    }
}

#product-review-container .bottom-fog {
    bottom: -60px;
}

.bottom-fog {
    display: block;
    width: 100%;
    background: linear-gradient(to bottom, rgba(white, 0) 0%, rgba(white, .75) 35%, white 55%);
    z-index: 1;
    bottom: 0;
    height: 100px;
    position: absolute;
    opacity: 1;
    transition: opacity .15s ease-in;

    &.hidden {
        opacity: 0;
        transition: opacity .15s ease-out;
    }
}

.toggle-button {
    margin-top: 20px;
    font-size: 1.4rem;
}

.product-info--responsive-main {
    .toggle-button,
    .bottom-fog {
        display: none !important;
    }
}

@include media('>phone') {
    .bottom-fog {
        display: none;

    }
}

.product-reviews-summary .reviews-actions a {
    white-space: nowrap;
    position: relative;
    z-index: 1;
    color: #7d879c;
}

.product-reviews-summary {
    .rating-summary {
        left: 0;
    }
}

#CybotCookiebotDialog {
    background-color: #000 !important;
    border-bottom: 2px solid #f2df00 !important;
    padding: 20px 0 !important;
    color: #fff !important;
    box-shadow: none !important;

    #CybotCookiebotDialogBody {
        max-width: 1440px !important;
        padding: 0 30px !important;
        margin-left: auto !important;
        margin-right: auto !important;
        display: flex !important;
        justify-content: space-between !important;

        @media screen and (min-width: 1440px) {
            padding: 0 !important;
        }

        @include media(">phone", "<=tablet") {
            padding: 0 20px !important;
        }

        @include media("<=phone") {
            padding: 0 15px !important;
        }

        @media screen and (max-width: 539px) {
            flex-direction: column;
        }
    }

    #CybotCookiebotDialogBodyButtons {
        margin: 0 !important;
        padding: 0 !important;
        display: flex;
        align-items: center;

        @media screen and (max-width: 539px) {
            justify-content: space-between;
            margin-top: 15px !important;
        }
    }

    #CybotCookiebotDialogBodyContent {
        padding: 0 !important;
        margin: 0 !important;
        display: flex;
        align-items: center;
    }

    #CybotCookiebotDialogBodyContentTitle {
        color: #fff !important;
        margin: 0 10px 0 0 !important;
        line-height: 1.2 !important;
    }

    #CybotCookiebotDialogBodyContentText {
        p {
            margin: 0 !important;
        }
    }

    #CybotCookiebotDialogBodyButtonAccept {
        margin: 0 !important;
        height: 50px !important;
        border-radius: 0 !important;
        border: 2px solid #f2df00 !important;
        background: transparent !important;
        color: #f2df00 !important;
        line-height: 50px !important;
        text-transform: uppercase !important;
        font-weight: bold !important;
        min-width: 175px;

        &:hover {
            color: #000 !important;
            background-color: #f2df00 !important;
        }
    }
}

#CybotCookiebotDialogDetail {
    position: relative;
    top: 20px;

    #CybotCookiebotDialogDetailBody {
        max-width: 1440px !important;
    }
}

.product-info-main .box-tocart {
    @include media("<=phone") {
        margin-bottom: 0;
    }
}

.d-none {
    display: none;
}
