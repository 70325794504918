$layout-column-main__sidebar-offset: 2%;
$layout-column__additional-sidebar-offset: $layout-column-main__sidebar-offset;

//
//  Common
//  _____________________________________________

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    box-sizing: border-box;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .breadcrumbs,
    .footer-columns--wrapper,
    .page-wrapper > .widget,
    .page-wrapper > .page-bottom,
    .block.category.event,
    .top-container {
        padding-left: $layout__width-xs-indent;
        padding-right: $layout__width-xs-indent;
    }

    .page-main {
        .account &,
        .cms-privacy-policy & {
            padding-top: 41px;
            position: relative;
        }
    }
}

div {
    box-sizing: border-box;
    display: block;
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .navigation,
    .page-main,
    .page-wrapper--top,
    .footer-columns--wrapper,
    .page-wrapper > .page-bottom,
    .block.category.event,
    .header__usps,
    .product-page-top-wrapper,
    .top-container {
        @include container-size;
        width: 100%;
    }

    .columns {
        display: block;
    }

    .column.main {
        @extend .abs-add-box-sizing-desktop;
        @include lib-layout-columns__main();
    }

    .sidebar-main {
        @extend .abs-add-box-sizing-desktop;
        @include lib-layout-columns__left();
        padding-right: 0;
    }

    .page-layout-2columns-right .sidebar-main {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    .sidebar-additional {
        @extend .abs-add-box-sizing-desktop;
        @include lib-layout-columns__right();
        clear: right;
        padding-left: 0;
    }

    .page-layout-2columns-left {
        .sidebar-main {
            width: 100%;
        }

        .sidebar-additional {
            clear: left;
            float: left;
            padding-left: 0;
            padding-right: 0;
            width: 100%;
        }
    }

    .panel.header {
        padding: 10px 0;
    }
}


.contact-index-index {
    padding: 0;

    .block {
        padding: 0;
    }

    .page-main {
        .block {
            padding: 0;
        }

        @include media('<=tablet') {
            padding: 0 15px 30px 15px !important;
        }
    }
}

.cms-page-view,
.cms-index-index,
.faq-category-view {
    .page-main {
        width: 100%;
        max-width: 100%;
    }
}

.columns .column.main {
    padding: 0;

    @media screen and (max-width: 767px) {
        padding-bottom: 0;
    }
}

.faq-faq-view .columns .column.main {
    overflow: hidden;
}
