.contact-index-index {
    .page-main {
        max-width: 900px;
        margin: 0 auto;
        padding: 2.5rem 0 5rem 0;

        .page-title-wrapper {
            .page-title {
                margin-bottom: 0;
            }
        }

        .column.main {
            @include media(">phone") {
                display: flex;
            }

            #contact-form {
                @include media(">phone") {
                    width: 70%;
                }
            }

            [data-content-type="row"] {
                @include media("<phone") {
                    margin-top: 2rem;
                }
                @include media(">phone") {
                    width: 25%;
                    margin-left: 5% !important;
                }
            }
        }
    }
}
