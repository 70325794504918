.price-box.price-final_price {
    display: flex;
    flex-direction: column;

    .price-label {
        @include visible-for-screenreader;
    }

    .old-price,
    .special-price {
        margin: 0;
    }

    .special-price {
        .price-wrapper {
            span span.price {
                margin-top: 0;
            }
        }
    }
}

.product-info-main .box-tocart .input-text.qty {
    height: 50px;
    width: 50px;
}
