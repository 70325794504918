%medium-dark-button {
    background-color: #fff;
    height: 40px;
    border: 2px solid #1A2021;
    border-radius: 0;
    appearance: none;
    font-size: 16px;
    font-family: $font-family__sans-serif--bold;
    font-weight: normal;

    > span {
        position: relative;
        top: 2px;
    }

    &:hover {
        background-color: #1A2021;
        color: #F2DF00;
    }
}

.tocart {
    &.primary {
        background-color: $primary--light !important;
        color: #000 !important;
        width: auto;
        height: 50px;
        border: none !important;
        font-size: 1.7rem;
        font-weight: 700;
        position: relative;

        body[class*=catalog-product] & {
            margin-top: 0;
            width: auto;
            max-width: 400px;
            padding-right: 60px !important;
        }

        @include media(">phone","<=tablet") {
            padding: 0 80px 0 15px;
        }

        &:hover {
            border: none;
            background-color: #F2DF00;
            color: #000;

            .arrow-container {
                display: block;
            }
        }

        .button-text-container {
            position: relative;
            display: flex;
            width: 100%;
            justify-content: flex-start;
            align-items: center;

            body[class*=catalog-product] & {
                justify-content: center;
            }

            i {
                font-style: normal;
            }

            .icon-cart {
                background: url('../svg/cart.svg') no-repeat center;
                background-size: contain;
                height: 22px;
                width: 22px;
                display: block;
                margin-right: 12px;

                + span {
                    @include media(">phone", "<=tablet") {
                        display: none;

                        body[class*=catalog-product] & {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}

.checkout-payment-method .payment-methods .actions-toolbar .primary.checkout, .checkout-cart-index .cart-container .cart-summary .checkout button {
    @extend .tocart.primary;
    width: 100%;
    max-width: 400px;
    margin-left: auto;
    display: block;

    .button-text-container {
        top: 2px;
        justify-content: center;

        .icon-cart {
            margin-right: 16px;

            + span {
                @include media(">phone", "<=tablet") {
                    display: block;
                }
            }
        }
    }

    .arrow-container {
        display: block;
    }

    &:hover {
        .arrow-container {
            background: #F2DF00;

            &::before {

                border-color: transparent transparent #F2DF00 transparent;
            }
        }
    }
}

.cart-container .form-cart  {
    .clear {

        .checkout-cart-index & {
            display: none !important;
        }
    }

    .action.continue, .update {
        @extend %medium-dark-button;

        border: none;

        &:hover {
            background-color: white;
            color: #000;
            border: none
        }
    }

    .clear, .update {
        span {
            top: 0;
        }
    }

    .action.continue {
        span {
            top: 2px;
        }

        &::before {
            vertical-align: middle;
            position: relative;
            top: 1px;
        }
    }
}

.product-info-main .box-tocart .actions {
    padding-top: 0 !important;

    @include media(">tablet", "<=desktop") {
        display: block;
        margin-top: 10px;
    }
}

.cart.table-wrapper .items {
    thead + .item {
        border-top: 2px solid #E8E8E8;
    }

    > .item {
        border-bottom: 2px solid #E8E8E8;
    }
}
