.cart-container {
    .form-cart {
        @include media("<=desktop") {
            width: 65%;
        }

        @include media("<=phone") {
            width: 100%;
        }

        .cart.table-wrapper {
            .cart.item {
                .product-item-photo {
                    background-color: transparent;
                }
            }
        }

        .actions {
            .continue {
                display: flex;
                align-items: center;
            }
        }
    }

    .cart-summary {
        @include media("<=desktop") {
            width: 33%;
        }

        @include media("<=phone") {
            width: 100%;
            position: fixed !important;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0;
            z-index: 9999;
            border-top: 1px solid #eee;
            padding-bottom: 3rem;
            top: unset !important;
        }


        > strong {
            @include media("<=phone") {
                display: none;
            }
        }

        tr {
            th,
            td {
                @include media("<=phone") {
                    padding: 0 !important;
                    font-size: 1.3rem;
                }
            }

            .amount {
                strong {
                    .price {
                        @include media("<=phone") {
                            font-size: 1.8rem !important;
                        }
                    }
                }
            }
        }

        #block-discount {
            .title {
                @include media("<=phone") {
                    padding: 0;
                }
            }

            #block-discount-heading {
                @include media("<=phone") {
                    font-size: 1.2rem;
                    display: block;
                    margin: 0.5rem 0;
                }
            }
        }

        .checkout-methods-items {
            button {
                max-width: unset !important;

                &:hover {
                    background-color: $primary--light__hover !important;
                }
            }
        }
    }
}
