.faq-category-view .bluefoot-driver,
.faq-faq-view .bluefoot-driver {
  height: 235px;
  @include media("<=desktop") {
    height: 185px;
  }
  @include media("<=phone") {
    height: 105px;
  }
  a {
    align-items: center;
    border: 1px solid $secondary--light;
    display: flex;
    justify-content: center;
    height: 100%;
    position: relative;
    &:hover {
      box-shadow: 0 0 5px 0 rgba($black, .2);
      &:after {
        display: block;
      }
      svg {
        display: block;
      }
    }
  }

  img {
    display: inline-block;
    position: relative;
    top: -20px;
    @include media("<=phone") {
      margin-right: 20px;
      top: 0;
    }
  }

  &.bluefoot-entity{
    @include media("<=phone") {
      margin-bottom: 15px;
    }
  }

  .bluefoot-driver-text {
    background: transparent;
    color: $black;
    font-family: $font-family__title;
    @include media("<=desktop") {
      padding: 15px 30px;
    }
    @include media("<=phone") {
      padding: 0;
      position: inherit;
    }
  }

  svg {
    bottom: 15px;
    display: none;
    position: absolute;
    right: 20px;
    z-index: 10;
  }
}

.faq-usps .bluefoot-row .bluefoot-wrapper.columns-3 .bluefoot-column-4{
  @include media("<=phone") {
    float: inherit;
    width: 100%;
  }
}