.catalog-category-view {
    .page-main,
    .columns .catalog-topnav amasty-catalog-topnav {
        max-width: 100%;
        width: 100%;
    }

    .columns #amasty-shopby-product-list .message,
    .products.products-grid {
        @include container-size();
    }
}

.catalog-topnav {
    float: inherit;
    order: inherit;

    @include media("<=phone") {
        background: $white;
        padding-left: 15px;
        padding-right: 15px;
    }

    .filter-options-content {
        position: inherit;
        position: inherit;
        background-color: white;
        z-index: inherit;
    }
}

body.page-layout-2columns-left:not(.faq-category-view) .sidebar-main {
    padding-right: 20px;
    order: unset;
    float: none;
}

.block.filter {
    margin-bottom: 0;
    position: sticky;
    top: 100px;
    z-index: 9;
    background: #fff;
    border-bottom: 1px solid #e3e3e3;

    .filter--content-wrapper {
        display: none;
    }

    &.open {
        background: #fff;

        .filter--content-wrapper {
            display: block;
        }

        @include media("<=phone") {
            background: $white;
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            z-index: 200;

            .filter-content {
                padding: 0 15px;
            }

            .filter--top-wrapper .filter--top {
                padding: 0;
            }

            .filter--top button {
                color: $black;
                border: none;
                padding: 0 15px;
                text-align: left;
                width: 100%;

                &.active svg.icon--active {
                    fill: $black;
                    height: 16px;
                    right: 0;
                    top: 0;
                    width: 16px;
                }

                span {
                    display: block;
                    position: relative;
                    width: 100%;
                }

                > span:before,
                > span:after {
                    display: none;
                }
            }
        }
    }
}

.filter--options-wrapper {
    background: $white;
    padding-top: 2rem;

    @include media('<=phone') {
        padding-top: 20px;
    }
}

.filter--top-wrapper,
.filter--options {
    @include container-size();
    width: 100%;
}

.filter--top-wrapper .filter--top {
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 0;

    @include media('<=phone') {
        padding: 1rem 1.5rem;
        flex-direction: column-reverse;

    }
}

.filter--content-wrapper {
    border-top: 1px solid $secondary--light;
    position: absolute;
    width: 100%;
    z-index: 3;

    @include media("<=phone") {
        position: relative;
    }

    &:after {
        background-image: linear-gradient(0deg, rgba($white, 0) 77%, $black 100%);
        content: '';
        display: block;
        height: 40px;
        margin-bottom: 40px;
        opacity: 0.2;
        width: 100%;
    }
}

.filter.open .filter-current {
    @include media('<=phone') {
        padding: 0 15px;
    }
}

.filter-current {
    &,
    & .items {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .filter-current-subtitle {
        font-family: $font-family__title;
        font-weight: 600;
        margin: 0 15px 0 0;
        font-size: 1.4rem;

        &:after {
            content: ':';
        }
    }

    .filter-label {
        @include visible-for-screenreader();
    }

    .items .item {
        border: 2px solid $secondary--light;
        margin: 7px 15px 7px 0;
        padding: 0.5rem !important;
        position: relative;
        font-size: 1.4rem;
        font-weight: 500;
        display: flex;

        .amshopby-filter-name {
            font-weight: 600;
        }

        .amshopby-remove {
            left: 10px;
        }

        .amshopby-filter-value {
            margin-left: 0.5rem;
        }

        &:hover {
            background: $secondary--light;
        }
    }

    .am_shopby_state_container a {
        position: absolute;
        left: 0;
        top: 0;
        text-align: right;
        width: 100%;

        &:before {
            color: $secondary--dark;
            content: "\00d7";
            font-size: 24px;
            margin-right: 5px;
            position: relative;
            top: -1px;
        }

        span {
            @include visible-for-screenreader();
        }
    }
}

.filter-content .filter-options {
    display: flex;
    list-style-type: none;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;

    > .filter-list {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(100% * 1 / 6 - 25px);
        max-width: calc(100% * 1 / 6 - 25px);
        width: calc(100% * 1 / 6 - 25px);

        &:nth-child(n) {
            margin-right: 30px;
            margin-left: 0;
        }

        &:nth-child(6n) {
            margin-right: 0;
            margin-left: auto;
        }

        @include media(">tablet", "<=desktop") {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: calc(100% * 1 / 5 - 24px);
            max-width: calc(100% * 1 / 5 - 24px);
            width: calc(100% * 1 / 5 - 24px);

            &:nth-child(n) {
                margin-right: 30px;
                margin-left: 0;
            }

            &:nth-child(5n) {
                margin-right: 0;
                margin-left: auto;
            }
        }

        @include media(">phone", "<=tablet") {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: calc(100% * 1 / 3 - 20px);
            max-width: calc(100% * 1 / 3 - 20px);
            width: calc(100% * 1 / 3 - 20px);

            &:nth-child(n) {
                margin-right: 30px;
                margin-left: 0;
            }

            &:nth-child(3n) {
                margin-right: 0;
                margin-left: auto;
            }
        }

        @include media("<=phone") {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: calc(100% * 1 / 2 - 15px);
            max-width: calc(100% * 1 / 2 - 15px);
            width: calc(100% * 1 / 2 - 15px);
            margin-bottom: 20px;

            &:nth-child(n) {
                margin-right: 30px;
                margin-left: 0;
            }

            &:nth-child(2n) {
                margin-right: 0;
                margin-left: auto;
            }
        }
    }
}

.filter-options .filter-options-title {
    font-family: $font-family__title;
    font-weight: 500;
    font-size: 1.5rem;
}

.filter-options .filter-options-content .item {
    color: $secondary--dark;
    margin-left: 0;

    a.am_shopby_link_selected {
        color: $primary--dark;
        font-weight: 600;
    }
}

.block-actions.filter-actions {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0;

    @include media('<=phone') {
        margin-bottom: 60px;
    }

    a.filter-clear {
        font-family: $font-family__title;
        font-size: 15px;
        line-height: 29px;
        color: $black;
    }

    .filter.close {
        font-size: 1.5rem;
        position: relative;
        display: flex;
        align-items: center;

        &::after {
            content: "";
            width: 15px;
            height: 15px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-x' viewBox='0 0 16 16'%3E%3Cpath d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-size: 22px;
            background-position: center;
            margin-left: 0.5rem;
        }

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}

.filter--top button {
    background: $white;
    border-color: $black;
    color: $black;

    > span:before {
        background: $black;
    }

    > span:after {
        border-color: transparent transparent $black transparent;
    }

    &:hover {
        color: $white;
    }

    svg {
        height: 16px;
        position: absolute;
        right: -63px;
        top: 2px;
        width: 16px;
        z-index: 5;
        fill: $primary--light;

        &.icon--active {
            display: none;
            top: -5px;
            height: 30px;
            width: 30px;
            right: -70px;
        }
    }

    &.active {
        svg {
            display: none;

            &.icon--active {
                display: block;
            }
        }
    }
}


@include media('>=phone', "<=desktop") {
    .filter-options .filter-list {
        min-height: 200px;
    }
}

@include media('<=phone') {
    .no-scroll {
        overflow-y: hidden;
        height: 100%;
        width: 100%;
        position: fixed;
        white-space: nowrap;
    }

    .block.filter.open {
        overflow-y: scroll;
        height: 100vh;
    }

    .filter--content-wrapper:after {
        display: none;
    }
}

.am-filter-current {

    .item.am-shopby-item {
        padding: 2px 40px 5px 14px;

        .am-filter-value {
            margin-left: 0;
        }
    }

    .action.remove {
        .icon-remove {
            &::before {
                content: "";
                width: 12px;
                height: 12px;
                position: absolute;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-x' viewBox='0 0 16 16'%3E%3Cpath d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-size: 20px;
                background-position: center;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
            }
        }
    }
}

.filter-list {
    ol.items {
        line-height: 1.7;

        li.item {
            .amshopby-link-selected {
                label:before,
                span.label:before {
                    background: $black;
                    border-color: $black;
                }

                label:after,
                span.label:after {
                    content: '';
                    position: absolute;
                    width: 18px;
                    height: 13px;
                    left: 0;
                    top: 2px;
                    background-image: url("../svg/favour-check.svg");
                    background-repeat: no-repeat;
                    background-size: 10px;
                    background-position: center;
                }
            }

            a {
                &:hover {
                    text-decoration: none;
                    color: $primary--light;
                }

                .label {
                    position: relative;
                    font-size: 1.4rem;
                    color: $black;
                }

                .count {
                    display: none;
                }

                label:before,
                span.label:before {
                    content: '';
                    position: relative;
                    top: 0;
                    margin-right: 10px;
                    display: inline-block;
                    vertical-align: text-top;
                    width: 16px;
                    height: 16px;
                    background-color: $white;
                    border-radius: 0;
                    border: 1px solid $secondary--dark;
                }
            }

            input {
                opacity: 0;
                visibility: hidden;
            }
        }
    }
}

body.page-with-filter {
    &:before {
        content: "";
        background-color: rgba($black, 0.35);
        top: 0;
        left: 0;
        right: 0;
        position: fixed;
        height: 100vh;
        z-index: 3;
        opacity: 0;
        visibility: hidden;
    }

    &.filters-open {
        &:before {
            opacity: 1;
            visibility: visible;
        }
    }
}
