.fotorama__thumb__arr.fotorama__thumb__arr--right {
    background: $white;
}

.fotorama__thumb__arr--right {
    position: relative;

    @include media(">=tablet") {
        &:after {
            bottom: -20px;
            border-color: transparent transparent $white transparent;
            border-style: solid;
            border-width: 0 0 160px 22px;
            content: '';
            height: 0;
            right: 30px;
            position: absolute;
            width: 0;
            z-index: 5;
        }

        &:before {
            bottom: -20px;
            border-color: transparent transparent $secondary--light transparent;
            border-style: solid;
            border-width: 0 0 160px 22px;
            content: '';
            height: 0;
            right: 32px;
            position: absolute;
            width: 0;
            z-index: 5;
        }
    }
}

.fotorama__nav--thumbs .fotorama__nav__frame {
    .fotorama__thumb {
        border: 1px solid $secondary--light;
    }
}

.fotorama__thumb-border,
.fotorama__thumb-border {
    background-image: none;
}

/**
  Overwrite fotorama mobile setup
  - hide thumb images
  - show bullet dots instead of images
 */
@include media("<phone") {
    .fotorama__thumb-border {
        display: none;
    }

    .fotorama__nav--thumbs .fotorama__nav__frame {
        width: 10px !important;
        height: 10px !important;

        .fotorama__thumb {
            border-radius: 50%;
            background: $gallery-nav--light;
        }

        img {
            display: none;
        }
    }

    .fotorama__nav__frame.fotorama__nav__frame--thumb.fotorama__active .fotorama__thumb {
        background: $gallery-nav--dark;
    }

    .fotorama__wrap--css3 .fotorama__nav {
        height: 30px !important;
        text-align: center;
    }
}

.fotorama__nav--thumbs .fotorama__nav__frame .fotorama__thumb {
    border: none;
}

.fotorama-video-container {
    .catalog-product-view & {
        &:after {
            border: 3px solid $primary--light;
            background: $primary--light;
        }

        &::before {
            border-left: 30px solid $black;
        }
    }
}

.fotorama__caption__wrap {
    display: none !important;
}

.fotorama__arr {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 80px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    z-index: 900;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.3);
    transition: background-color 0.3s ease-in-out;
}

.fotorama__nav-wrap {
    @include media(">tablet") {
        margin-top: 30px;
    }
}

.fotorama-item {
    .fotorama__stage {
        @include media("<phone") {
            height: 275px !important;
        }

        @include media(">tablet") {
            height: 650px !important;
        }

        .fotorama__img {
            @include media("<phone") {
                height: inherit;
            }
        }
    }
}
