@mixin slider-height() {
    @include media("<=phone") {
        height: 225px;
    }

    @include media(">=phone") {
        height: 325px;
    }

    @include media(">=tablet") {
        height: 425px;
    }

    @include media(">=desktop") {
        height: 525px;
    }
}

@mixin hero-image-height() {
    @include media("<=phone") {
        height: 175px;
    }

    @include media(">=phone") {
        height: 225px;
    }

    @include media(">=tablet") {
        height: 325px;
    }

    @include media(">=desktop") {
        height: 380px;
    }
}


[data-content-type='slider'] {
    .pagebuilder-slide-wrapper {
        @include hero-image-height();

        .pagebuilder-overlay {
            @include container-size();
        }
    }
}


[data-content-type='slider'] {
    background-color: #000;
    overflow: hidden;
    min-height: unset !important;

    .pagebuilder-slide-wrapper {
        @include slider-height();
        max-width: 2500px;
        margin: 0 auto;
        min-height: unset !important;

        @media only screen and (min-width: 1900px) {
            background-size: contain !important;
            background-position: center !important;
        }

        @include media("<=phone") {
            background-position: center !important;
        }

        .pagebuilder-overlay {
            height: 100%;
            min-height: unset !important;
            padding: 0;

            @include media("<=phone") {
                padding: 0 15px;
            }

            .pagebuilder-poster-content {
                @include container-size();

                &:before,
                &:after {
                    display: none;
                }
            }
        }
    }

    [data-content-type='slide'] {
        min-height: unset !important;
    }

    .slick-arrow {
        bottom: 3rem;
        top: unset;
        transform: unset;
        height: 30px;
        width: 30px;
        padding: 0;
        z-index: 1;

        @include media("<=tablet") {
            display: none !important;
        }

        &:before {
            display: none;
        }

        &.slick-prev,
        &.slick-next {
            background-color: $primary--light;
            mask-repeat: no-repeat;
            mask-position: center;
            mask-size: 10px;
        }

        &.slick-prev {
            mask-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.2.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Laag_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='5.2px' height='9.2px' viewBox='0 0 5.2 9.2' style='enable-background:new 0 0 5.2 9.2;' xml:space='preserve'%3E%3Cpath d='M3.3,4.6L0.2,1.2c-0.3-0.3-0.3-0.7,0-1c0.3-0.3,0.7-0.3,1,0c0,0,0,0,0,0L5,4.1c0.3,0.3,0.3,0.7,0,1L1.2,9 c-0.3,0.3-0.7,0.3-1,0c0,0,0,0,0,0c-0.3-0.3-0.3-0.7,0-1L3.3,4.6z'/%3E%3C/svg%3E%0A");
            left: unset;
            right: 30rem;
            transform: scaleX(-1);
        }

        &.slick-next {
            mask-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.2.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Laag_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='5.2px' height='9.2px' viewBox='0 0 5.2 9.2' style='enable-background:new 0 0 5.2 9.2;' xml:space='preserve'%3E%3Cpath d='M3.3,4.6L0.2,1.2c-0.3-0.3-0.3-0.7,0-1c0.3-0.3,0.7-0.3,1,0c0,0,0,0,0,0L5,4.1c0.3,0.3,0.3,0.7,0,1L1.2,9 c-0.3,0.3-0.7,0.3-1,0c0,0,0,0,0,0c-0.3-0.3-0.3-0.7,0-1L3.3,4.6z'/%3E%3C/svg%3E%0A");
            right: 6rem;
        }
    }

    .slick-dots {
        position: absolute;
        bottom: 0;
        right: 0;

        @include media("<=phone") {
            width: 10rem;
            padding-bottom: 2rem;
            padding-right: 1rem;

        }

        @include media(">=phone") {
            width: 20rem;
            padding-bottom: 3rem;
            padding-right: 5rem;
        }

        @include media(">=tablet") {
            width: 30rem;
            padding-right: 9rem;
        }

        &:before,
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            right: -20rem;
            transform: skewX($skew-angle);
            @include slider-height();
            pointer-events: none;
        }

        &:before {
            background: $primary--light;
            background: linear-gradient(0deg, rgba($primary--light, 0.9) 0%, rgba($primary--light, 0.15) 100%);

            @include media("<=phone") {
                left: -30px;
                width: 30px;
            }

            @include media(">=phone") {
                left: -40px;
                width: 40px;
            }

            @include media(">=tablet") {
                left: -60px;
                width: 60px;
            }
        }

        &:after {
            background-color: rgba($black, 0.5);
            left: 0;
            @include slider-height();
        }

        li {
            z-index: 1;
            width: auto;
            height: auto;
            margin-top: 0.75rem;

            @include media("<=phone") {
                margin-left: 2px;
                margin-right: 2px;
            }

            &.slick-active {
                button {
                    background-color: $white;
                }
            }

            button {
                border: 0;
                border-radius: 0;
                transform: skewX($skew-angle);
                background-color: rgba($white, 0.4);

                @include media("<=phone") {
                    width: 15px;
                    height: 15px;
                }

                @include media(">=phone") {
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }
}
