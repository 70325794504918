input[type="text"],
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="search"],
input[type="number"],
input[type="datetime"],
input[type="email"] {
    border: 1px solid $secondary--light;
    @include font();
    color: $primary--dark;

    &:focus {
        border: 1px solid $secondary--dark;
        color: $primary--dark;
        font-style: normal;
    }
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
    color: inherit;
    -webkit-box-shadow: 0 0 0px 1000px $white inset; // remove chrome yellow background
}

._keyfocus *:focus,
input:not([disabled]):focus,
textarea:not([disabled]):focus,
select:not([disabled]):focus {
    box-shadow: none;
}

.field .control input,
.field .control textarea {
    padding: 0 10px;
    color: $primary--dark;
    border-radius: 0;
    font-family: $font-family__sans-serif--light;
    font-size: 15px;
    border: 1px solid $secondary--light;

    &:focus {
        border: 1px solid $secondary--dark;
    }
}

.field .control input:not([type="radio"]) {
    height: 50px;
    line-height: 50px;
}

.field .control textarea {
    padding: 10px 20px;
}

.mage-error {
    @extend %message__error;
    padding: 10px;
}

input,
select,
textarea {
    &.mage-error {
        @extend %message__error;
    }
}

.abs-field-tooltip input,
.field .control._with-tooltip input {
    margin: inherit;
    width: 100%;
}
