//pass breakpoints to theme-default blocks/_layout.scss
@import "variables/media-breakpoints";
@import "mixins/font-smoothing";

$font-family__base: $font-family__sans-serif;

$checkout-sidebar__columns: 4 !default;

$color-blue1: #747474;
$color-blue2: darken($color-blue1, 20);
$color-sky-blue1: $color-blue1;

$color-orange-red1: $color-blue1;
$color-orange-red2: lighten($color-blue1, 20);
$color-orange-red3: $color-orange-red1;
$color-orange-red4: $color-orange-red2;

//bluefoot vars
$bf-font-size--small: 16px;
$bf-margin: 30px;
$bf-border_color: #e4e4e4;

$line-height__base: 1.8;

$skew-angle: -20deg;
