
body {
    color: $primary--dark;
}

h1 {
    color: $default-font-color;
    font-family: $font-family__title;
    font-weight: 700;
    text-transform: uppercase;

    .faq-category-view .page-title-wrapper &,
    .category-image &,
    .product & {
        $h1-blackbox--width: 44;
        $h1-blackbox--width__mobile: $h1-blackbox--width / 2;
        $h1-blackbox--offsetheight: 5;
        position: relative;
    }
}

h2 {
    @include media("<=phone") {
        font-size: 2.2rem;
    }
}

h2,
h3,
h4,
h5,
h6 {
    font-family: $font-family__title;
    text-transform: uppercase;
    font-weight: 700;
}


body,
p {
    font-family: $font-family__serif;
    font-size: 15px;
    line-height: 28px;
}

address {
    line-height: 18px;
}

.service--content h5 {
    font-weight: bold;
    font-size: responsive 13px 24px;
    line-height: 34px;
}

ul,
ol {
    list-style-type: square;

    &,
    & li {
        margin: 0 0 0 10px;
        padding: 0;
    }
}
