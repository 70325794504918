
/* Snowdog hack to set * for required fields with _required class and .required, instead of only the  .required class. */
.fieldset > .field.required > .label:after,
.fieldset > .fields > .field.required > .label:after,
.fieldset > .field._required > .label:after,
.fieldset > .fields > .field._required > .label:after {
    content: '*';
    color: #FA8200;
    font-family: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
    font-family: 'Arial';
    font-size: 18px;
    margin: -2px 0px 0px 3px;
}

form {
    &.form {
        &.form-create-account {
            .field {
                &.choice {
                    display: none;
                }
            }
        }

        .fieldset {
            margin-bottom: 0;

            &:after {
                display: none;
            }

            .field {
                margin-bottom: 1.5rem;

                &._required,
                &.required {
                    label {
                        &.label {
                            &:after {
                                top: 5px;
                                margin: 0 0 0 5px;
                            }
                        }
                    }
                }

                &.choice,
                &.note {
                    &:before {
                        display: none;
                    }
                }

                label {
                    &.label {
                        font-family: $font-family__base;
                        float: none;
                        width: 100%;
                        text-align: left;
                        padding: 0;
                        font-size: 1.3rem;
                        color: #555;
                        font-weight: 500;
                        margin-bottom: 0.5rem;
                    }
                }

                .control {
                    float: none;
                    width: 100%;

                    input {
                        &:not([type="radio"]) {
                            height: 5.2rem;
                            border: 1px solid #d8d8d8;
                            padding: 0 1.6rem;
                            width: 100%;

                            &:focus {
                                box-shadow: none;
                            }
                        }
                    }
                }
            }
        }

        .actions-toolbar {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-left: 0 !important;

            &:before,
            &:after {
                display: none;
            }

            .remind,
            .back {
                color: #000;
                font-size: 1.2rem;
                margin: 0;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

