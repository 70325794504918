.flag,
.icon {
  fill: currentColor;
  position: relative;
}

.footer .social .icon {
  fill: $white;
  height: 32px;
  width: 32px;
}

.switcher-language .flag {
  height: 16px;
  width: 22px;
  top: 3px;
  margin-right: 5px;
}

.bottombar--store-switcher .icon {
  fill: $secondary--dark;
  height: 10px;
  width: 10px;
}

.bottombar--store-switcher .icon {
  width: 10px;
  height: 10px;
  position: absolute;
  right: 10px;
  top: 15px;
  transform: rotate(90deg);
}

.bottombar--store-switcher .active .icon {
  transform: rotate(-90deg);
}
