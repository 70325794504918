.product-info-main .stock.available {
    font-family: $font-family__sans-serif--medium;
    color: $succes-content;
    font-weight: normal;
    text-transform: none;
    padding-left: 30px;
    position: relative;

    > span {
        position: relative;
        top: -2px;
    }

    &::before {
        content: "";

        width: 16px;
        height: 16px;
        position: absolute;
        background-image: url("../svg/check.svg");
        background-repeat: no-repeat;
        background-size: 16px;
        background-position: center;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }
}
