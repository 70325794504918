input[name="guest_email"].amxnotif_guest_email {
  display: inline-block;
  width: 100%;
  height: 50px;
}

.amxnotif-block label {
  color: $black;
  font-weight: bold;
  float: none;
}

.amxnotif_guest_action span {
  @include visible-for-screenreader;
}

.yellow-button--wrapper {
  margin-top: 15px;
  position: relative;
  svg {
    fill: $black;
    height: 16px;
    position: relative;
    top: 2px;
    width: 10px;
  }
}

.actions-toolbar.amxnotif_guest_action {
  margin: 0;
  position: absolute;
  top: 0;
  right: 0;
  min-width: auto;
}
