.select2-container {
    min-width: 100px;

    .select2-selection--single {
        height: 40px;
        border: 2px solid #E8E8E8;
        border-radius: 0;

        .select2-selection__rendered {
            line-height: 40px;
            color: #1A2021;
            position: relative;
            top: -3px;
            padding-left: 12px;
        }

        .select2-selection__arrow {
            top: 50%;
            transform: translateY(-50%);

            &::after {
                top: 50%;
                transform: translateY(-50%) rotate(90deg);
                right: 8px;
            }
        }
    }

    .select2-dropdown {
        border: 2px solid #E8E8E8;
        border-top: none;
    }


    &--default {

        .select2-results__options {
            color: #1A2021;
        }

        .select2-results__option {
            padding-left: 10px;
            margin: 0;

            &[aria-selected=true] {
                background-color: #f4f4f4;
            }

            &--highlighted[aria-selected] {
                color: #1A2021;
                border-color: #E8E8E8;
                padding-left: 10px;
                margin: 0;
                background-color: #F2DF00;
            }
        }
    }
}