.btn-secondary--yellow {
    border: 2px solid $primary--light;
    color: $primary--light;
    display: inline-block;
    height: 32px;
    line-height: 26px;
    padding: 2px 15px 0 15px;
}

.yellow-button--wrapper .amxnotif_guest_action .primary .action {
    background: $primary--light;
    border: none;
    border-radius: 0;
    display: inline-block;
    height: 50px;
    margin: 0;
    width: 52px;
    position: relative;
    right: 0px;
    top: 0;

    &:before {
        border-color: transparent transparent $primary--light transparent;
        border-style: solid;
        border-width: 0 0 50px 18px;
        content: '';
        height: 0;
        left: -18px;
        position: absolute;
        top: 0;
        width: 0;
    }
}


.button-secondary_attachment,
.toggle-button,
.review-form .action.submit.primary,
.review-items--toolbar button.read-all,
.bluefoot-button,
.bluefoot-button:active,
.bluefoot-button:focus {
    border-radius: 0;
    font-family: $font-family__title;
    font-size: 16px;
    font-weight: 400;
    background: $white;
    border: 2px solid $primary--dark;
    height: 40px;
    padding-top: 8px;
    transform: translateZ(0);
    position: relative;
    margin-bottom: 0;

    &:active,
    &:focus,
    &:hover {
        border-color: $primary--dark;
        background: $primary--dark;
        color: $primary--light;
    }
}

.button-secondary_attachment {
    display: inline-block;
    padding: 5px 15px 2px 15px;
    text-decoration: none;
    height: inherit;

    &:hover {
        text-decoration: none;
    }
}


.bluefoot-newsletter.block .actions .button,
.slider-button_header,
.category_banner--locationbox a.button,
.filter--top button {
    border: 2px solid black;
    border-radius: 0;
    font-family: $font-family__title;
    font-weight: 500;
    height: 48px;
    padding-right: 80px;
    transition: color 0.15s ease-in;
    font-weight: 600;
    overflow: hidden;

    > span {
        position: relative;

        &:before {
            content: '';
            width: 40px;
            height: 46px;
            display: block;
            position: absolute;
            right: -82px;
            top: -14px;

            @-moz-document url-prefix() {
                right: -80px;
            }
        }

        &:after {
            border-style: solid;
            border-width: 0 0 48px 18px;
            content: '';
            height: 0;
            right: -42px;
            position: absolute;
            top: -15px;
            transition: all .15s ease-in;
            width: 0;

            @-moz-document url-prefix() {
                right: -40px;
            }

        }
    }

    &:hover {

        text-decoration: none;

        span span {
            position: relative;
            z-index: 5;
        }

        > span:after {
            border-width: 0 0 48px 0px;
            width: calc(100% + 58px);
        }
    }
}


@include media('<=phone') {
    .filter--top button {
        text-align: left;
        width: 100%;

        > span {
            display: block;

            &:after {
                border-width: 0 0 47px 18px;
            }
        }

        &:hover {
            span span {
                display: block;
            }

            > span:after {
                border-width: 0 0 47px 0px;
            }
        }
    }
}


/* @START Black right corner with yellow arrow svg */
.product-item-box,
.faq-category-view .bluefoot-driver a:after,
.faq-faq-view .bluefoot-driver a:after {
    content: '';
    border-style: solid;
    border-width: 0 0 90px 90px;
    border-color: transparent transparent $black transparent;
    height: 0;
    width: 0;
}

.faq-category-view .bluefoot-driver svg,
.faq-faq-view .bluefoot-driver svg,
.product-item-box svg {
    fill: $primary--light;
    height: 16px;
    position: relative;
    width: 10px;
}

/* @END Black right corner with yellow arrow svg */

.action.primary {
    background-color: $primary--light;
    border-color: $primary--light;
    color: $black;
    border-radius: 0;
    padding: 1.5rem 2rem;

    &:hover {
        background-color: $primary--light__hover;
        border-color: $primary--light__hover;
        color: $black;
    }
}

