.message {
    box-sizing: border-box;
    font-family: $font-family__sans-serif;
    text-transform: normal;
    padding: 5px 10px;

    a {
        font-family: $font-family__sans-serif;
        text-decoration: underline;
    }

    &.info {
        background-color: $primary--light;
        border: 1px solid $primary--light;
        color: $black;
        margin: 10px 0 30px 0;

        a,
        a:hover,
        > *:first-child:before {
            color: $black;
        }
    }

    &.error {
        background-color: $error-bg;
        border: 1px solid $error-content;
        color: $error-content;
        margin: 10px 0 30px 0;

        a,
        a:hover,
        > *:first-child:before {
            color: $error-content;
        }
    }

    &.warning,
    &.notice {
        background-color: $warning-bg;
        border: 1px solid $warning-content;
        color: $warning-content;
        margin: 10px 0 30px 0;

        a,
        a:hover,
        > *:first-child:before {
            color: $warning-content;
        }
    }

    &.success {
        background-color: $primary--light;
        border: 1px solid $primary--light;
        color: $black;

        a,
        a:hover,
        > *:first-child:before {
            color: $black;
        }
    }

    &.info,
    &.error,
    &.warning,
    &.notice,
    &.success {
        margin: 10px 0 30px 0;
    }
}

.header__usps--wrapper .message {
    margin: 0;
}

.product-info-main .messages {
    .message {
        margin: 10px 0;

        &:first-child {
            margin: 40px 0 10px 0;
        }

        &:after {
            content: '';
            width: 0px;
            height: 0px;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            position: absolute;
            bottom: -8px;
            left: calc(50% - 8px);
        }

        &.info:after {
            border-top: 8px solid $primary--light_dark;
        }

        &.error:after {
            border-top: 8px solid $error-content;
        }

        &.warning:after,
        &.notice:after {
            border-top: 8px solid $warning-content;
        }

        &.success:after {
            border-top: 8px solid $primary--light_dark;
        }
    }
}

.catalog-product-view,
.cms-index-index {
    .message.error, .message.info, .message.notice, .message.success, .message.warning {
        margin: 0;

        > div {
            @include container-size;
            position: relative;

            &:before {
                width: inherit;
                top: -3px;
                margin: 0;
                left: -25px;
            }
        }
    }
}

.bottom-fog {
    @include media("<=phone") {
        display: none !important;
    }
}

.message.global.cookie {
    z-index: 999;
    background-color: #000;
    font-family: $font-family__base;
    border-top: 2px solid #f2df00;
    padding: 20px 30px;
    color: #fff;

    .content {
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: none;
        font-family: inherit;

        p {
            margin-right: 20px;
        }
    }

    .actions {
        margin: 0;
    }

    a {
        color: #fff;

        &:hover {
            color: #fff;
        }
    }

    button.action.primary {
        height: 50px;
        border-radius: 0;
        border: 2px solid #f2df00;
        background: transparent;
        color: #f2df00;
        line-height: 16px;
        text-transform: uppercase;
        font-family: "IntroBlackCaps";
        font-weight: bold;

        &:hover {
            color: #000;
            background-color: #f2df00;
        }
    }
}


