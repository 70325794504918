[data-content-type='banner'] {
    &[data-appearance='collage-left'] {
        .pagebuilder-banner-wrapper {
            @include slider-height();
            padding: 0 15px;

            .pagebuilder-collage-content {
                align-items: flex-start;

                &:before,
                &:after {
                    display: none;
                }
            }

            .pagebuilder-button-primary {
                background-color: $primary--light;
                color: #000;
                border-radius: 0;
                border: 0;

                &:hover {
                    background-color: $primary--light__hover;
                }
            }
        }
    }

    .pagebuilder-banner-wrapper {
        @include hero-image-height();

        .pagebuilder-overlay {
            @include container-size();
            height: 100%;
            min-height: unset !important;
            padding: 0;
        }
    }
}
