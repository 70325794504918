[data-content-type='buttons'] {
    margin-top: 2rem;
    margin: 2rem -15px 0 -15px;

    [data-content-type='button-item'] {
        padding: 0 15px;

        [data-element='link_text'] {
            color: $black;
        }
    }

    .pagebuilder-button-primary {
        display: flex;
        align-items: center;
        margin: 0;
        border-radius: 0;
        background-color: transparent;
        font-size: 1.6rem;
        border: 3px solid;
        border-image-slice: 1;
        border-width: 3px;
        border-image-source: linear-gradient(80deg, rgba($primary--light, 0.9) 0%, rgba($primary--light, 0.15) 100%);

        @include media("<=phone") {
            font-size: 1.4rem;
        }

        &:hover,
        &:active,
        &.active,
        &:focus {
            background: $primary--light;
            border: 3px solid $primary--light;
            color: $black;

            &:after {
                background-color: $black;
            }
        }

        &:after {
            content: "";
            mask-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.2.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Laag_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='5.2px' height='9.2px' viewBox='0 0 5.2 9.2' style='enable-background:new 0 0 5.2 9.2;' xml:space='preserve'%3E%3Cpath d='M3.3,4.6L0.2,1.2c-0.3-0.3-0.3-0.7,0-1c0.3-0.3,0.7-0.3,1,0c0,0,0,0,0,0L5,4.1c0.3,0.3,0.3,0.7,0,1L1.2,9 c-0.3,0.3-0.7,0.3-1,0c0,0,0,0,0,0c-0.3-0.3-0.3-0.7,0-1L3.3,4.6z'/%3E%3C/svg%3E%0A");
            width: 8px;
            height: 14px;
            display: inline-block;
            background-color: $black;
            margin-left: 1.5rem;
            mask-size: contain;
        }
    }

    .pagebuilder-button-secondary {
        font-size: 1.6rem;
        background-color: transparent;
        border: 3px solid $primary--light;

        &:hover,
        &:active,
        &.active,
        &:focus {
            background-color: $primary--light;
            border: 3px solid $primary--light;
            color: $black;
        }
    }
}

.row-divider-text {
    [data-content-type='buttons'] {
        [data-content-type='button-item'] {
            .pagebuilder-button-primary {
                &:after {
                    background-color: #fff;
                }
            }

            [data-element='link_text'] {
                color: #fff;
            }
        }
    }
}
