.breadcrumbs {
    margin: 0 auto;
    background: #1B1D1D;
    border-top: 1px solid $black;
    z-index: 3;
    position: relative;

    .breadcrumb {
        @include container-size;
    }

    .items {
        @include container-size;
        display: flex;
    }

    .item {
        display: flex;
        align-items: center;

        &:not(:last-child) {
            display: flex;
            align-items: center;

            &:after {
                content: '';
                mask-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.2.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Laag_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='5.2px' height='9.2px' viewBox='0 0 5.2 9.2' style='enable-background:new 0 0 5.2 9.2;' xml:space='preserve'%3E%3Cpath d='M3.3,4.6L0.2,1.2c-0.3-0.3-0.3-0.7,0-1c0.3-0.3,0.7-0.3,1,0c0,0,0,0,0,0L5,4.1c0.3,0.3,0.3,0.7,0,1L1.2,9 c-0.3,0.3-0.7,0.3-1,0c0,0,0,0,0,0c-0.3-0.3-0.3-0.7,0-1L3.3,4.6z'/%3E%3C/svg%3E%0A");
                background-color: $white;
                background-size: contain;
                height: 9px;
                width: 5px;
                margin: 0 1rem;
                display: inline-block;
            }
        }
    }


    .back-to-breadcrumbs {
        display: none;
    }

    a {
        font-size: 1.2rem;
        font-weight: 500;
        position: relative;
        color: $white;
        display: flex;
        align-items: center;

        @include media("<=phone") {
            font-size: 1.1rem;
        }

        &:visited {
            color: $white;
        }
    }

    .disabledlink,
    strong {
        color: #8b8b8c;
        font-size: 1.2rem;
        line-height: 1.7;

        &:visited {
            color: #8b8b8c;
        }
    }

    .breadcrumb {
        display: flex;
        align-items: center;

        div {
            margin-right: 0;
            display: flex;
            align-items: center;

            a {
                &:not(.disabledlink)::after {
                    content: '';
                    mask-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.2.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Laag_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='5.2px' height='9.2px' viewBox='0 0 5.2 9.2' style='enable-background:new 0 0 5.2 9.2;' xml:space='preserve'%3E%3Cpath d='M3.3,4.6L0.2,1.2c-0.3-0.3-0.3-0.7,0-1c0.3-0.3,0.7-0.3,1,0c0,0,0,0,0,0L5,4.1c0.3,0.3,0.3,0.7,0,1L1.2,9 c-0.3,0.3-0.7,0.3-1,0c0,0,0,0,0,0c-0.3-0.3-0.3-0.7,0-1L3.3,4.6z'/%3E%3C/svg%3E%0A");
                    background-color: $white;
                    background-size: contain;
                    height: 9px;
                    width: 5px;
                    margin: 0 1rem;
                    display: inline-block;
                }

                &.disabledlink {
                    &:hover {
                        text-decoration: none;
                        cursor: default;
                    }
                }
            }
        }
    }
}
